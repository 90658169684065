import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';

import { IState } from '../../store/cityQueries';
import { useCityQueriesPagination } from '../../utils/hooks';
import { CHART_COLORS } from '../../utils/constants';
import { citiesQueryItemToCityChartData, citiesQueryToFilterChartData } from '../../utils/cityCharts'

import ChartTooltip from '../tooltips/ChartTooltip';
import { Spin, Radio, Pagination } from 'antd';
import { CartesianGrid, XAxis, YAxis, Tooltip, TooltipProps, BarChart, Bar } from 'recharts';
import { Container, Chart, ChartTitle, PlaceholderContainer } from './styles/Charts.styles';

const QueryCitiesCharts = () => {
  const { query, page: cityChartsPage, pageSize, loading } = useSelector<any, IState>(state => state.cityQueries);
  const [filterChartsPage, setFilterChartsPage] = useState<number>(1);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [activeBar, setActiveBar] = useState<{ key: string, i: number }>({ key: '', i: -1 });
  const [chartType, setChartType] = useState<'city' | 'filter'>('city');
  const [filterChartBarPages, setFilterChartBarPages] = useState<number[]>([]);
  const pagination = useCityQueriesPagination();
  const filterChartsCount = useRef<number>(0);
  const container = useRef(null);

  useLayoutEffect(
    () => {
      if (!container.current) return;
      // @ts-ignore: Object is possibly 'null'
      const width: number = container.current.offsetWidth;
      // @ts-ignore: Object is possibly 'null'
      const height: number = container.current.offsetHeight;

      setDimensions({ width, height });
    },
    [],
  );

  useEffect(() => {
    setFilterChartsPage(1);
    setFilterChartBarPages([]);
  }, [query]);

  const handleRadioChange = (e: RadioChangeEvent): void => {
    setChartType(e.target.value);
  };

  const handleMouseLeave = (): void => setActiveBar({ key: '', i: -1 });

  const renderTooltip = (isSingleYear: boolean) => (props: TooltipProps): JSX.Element => (
    <ChartTooltip
      {...props}
      activeItem={activeBar}
      isSingleYear={isSingleYear}
    />
  );

  const cityChartsStartIndex: number = (cityChartsPage - 1) * pageSize;
  const cityChartsEndIndex: number = cityChartsStartIndex + pageSize;

  const getCityCharts = (query: ICitiesQuery): JSX.Element[] => {
    return query
      .slice(cityChartsStartIndex, cityChartsEndIndex)
      .map((item, i) => {
        const data: object[] = citiesQueryItemToCityChartData(query, item);

        const keys = Object.keys(item);
        const bars: (JSX.Element | null)[] = keys.map((key, j) => {
          const handleMouseOver = (): void => setActiveBar({ key, i });
          const isActiveBar: boolean = activeBar.key === key && activeBar.i === i;

          return (
            <Bar
              key={key}
              dataKey={`${key}.value`}
              name={key}
              fill={CHART_COLORS[j % CHART_COLORS.length]}
              opacity={isActiveBar ? 1 : 0.5}
              width={isActiveBar ? 7 : 5}
              onMouseOver={handleMouseOver}
            />
          );
        });

        return (
          <Chart key={item.political_area}>
            <ChartTitle>{item.political_area}</ChartTitle>
            <BarChart
              onMouseLeave={handleMouseLeave}
              width={dimensions.width}
              height={300}
              data={[data]}
              margin={{ top: 5, bottom: 5, left: 50, right: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="null" />
              <YAxis />
              <Tooltip
                content={renderTooltip(query.length === 1)}
                cursor={{ fill: 'transparent' }}
              />
              {bars}
            </BarChart>
          </Chart>
        );
      });
  };

  const filterChartsStartIndex: number = (filterChartsPage - 1) * pageSize;
  const filterChartsEndIndex: number = filterChartsStartIndex + pageSize;

  const getFilterCharts = (query: ICitiesQuery): JSX.Element[] => {
    const parsedQuery = citiesQueryToFilterChartData(query);
    filterChartsCount.current = parsedQuery.length;

    return parsedQuery
      .slice(filterChartsStartIndex, filterChartsEndIndex)
      .map((item, i) => {
        const barPage = filterChartBarPages[i] || 1;
        const barPageSize = 10;
        const firstBarIndex = (barPage - 1) * barPageSize;

        const itemCopy = {...item};
        delete itemCopy.filter;
        const keys = Object.keys(itemCopy);
        const total = keys.length;

        const activeKeys = keys.splice(firstBarIndex, barPageSize);

        const data = Object.fromEntries(
          Object.entries(itemCopy)
          .filter(([key]) => activeKeys.includes(key))
        );

        const bars: (JSX.Element | null)[] = activeKeys.map((key, j) => {
          const handleMouseOver = (): void => setActiveBar({ key, i });
          const isActiveBar: boolean = activeBar.key === key && activeBar.i === i;

          return (
            <Bar
              key={key}
              dataKey={`${key}.value`}
              name={key}
              fill={CHART_COLORS[j % CHART_COLORS.length]}
              opacity={isActiveBar ? 1 : 0.5}
              width={isActiveBar ? 7 : 5}
              onMouseOver={handleMouseOver}
            />
          );
        });

        return (
          <Chart key={item.filter}>
            <ChartTitle>{item.filter}</ChartTitle>
            <BarChart
              onMouseLeave={handleMouseLeave}
              width={dimensions.width}
              height={300}
              data={[data]}
              margin={{ top: 5, bottom: 5, left: 50, right: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="null" />
              <YAxis />
              <Tooltip
                content={renderTooltip(query.length === 1)}
                cursor={{ fill: 'transparent' }}
              />
              {bars}
            </BarChart>
            <Pagination
              size="small"
              total={total}
              current={barPage}
              onChange={p => {
                const newFilterChartBarPages = [...filterChartBarPages];
                newFilterChartBarPages[i] = p
                setFilterChartBarPages(newFilterChartBarPages)
              }}
            />
          </Chart>
        );
      });
  };

  const charts: JSX.Element[] | false = !!query && !!query.length && {
    city: getCityCharts(query),
    filter: getFilterCharts(query),
  }[chartType];

  const radio: JSX.Element = (
    <Radio.Group
      defaultValue={chartType}
      onChange={handleRadioChange}
      buttonStyle="solid"
      style={{ marginBottom: '1rem' }}
    >
      <Radio.Button value="city">Mostrar Gráficos Por Cidade</Radio.Button>
      <Radio.Button value="filter">Mostrar Gráficos Por Filtro</Radio.Button>
    </Radio.Group>
  );

  const placeholder: JSX.Element = (
    <PlaceholderContainer>
      Os seus gráficos aparecerão aqui
    </PlaceholderContainer>
  );

  const cityPagination: JSX.Element | false = chartType === 'city' && (
    <Pagination
      style={{ textAlign: 'right' }}
      size="small"
      {...pagination}
    />
  );

  const filterPagination: JSX.Element | false = chartType === 'filter' && (
    <Pagination
      style={{ textAlign: 'right' }}
      size="small"
      current={filterChartsPage}
      onChange={p => {
        setFilterChartsPage(p);
        setFilterChartBarPages([]);
      }}
      total={filterChartsCount.current}
      pageSize={pageSize}
    />
  );

  return (
    <Spin spinning={loading}>
      <Container ref={container}>
        {!!charts && !!charts.length && radio}
        {!!charts && !!charts.length ? charts : placeholder}
        {cityPagination}
        {filterPagination}
      </Container>
    </Spin>
  );
};

export default QueryCitiesCharts;
