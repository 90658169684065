import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { PaginationConfig } from 'antd/lib/table';

import { actions as queriesActions, IState as QuetiesState } from '../store/queries';
import { actions as cityQueriesActions, IState as CityQuetiesState } from '../store/cityQueries';

export const useQueriesPagination = (): PaginationConfig => {
  const { query, page, pageSize } = useSelector<any, QuetiesState>(state => state.queries);
  const dispatch: Dispatch = useDispatch();

  const handlePageChange = (page: number): void => {
    dispatch(queriesActions.setPage(page));
  };

  const pagination: PaginationConfig = {
    pageSize,
    current: page,
    onChange: handlePageChange,
    total: query.political_areas.length,
  };

  return pagination;
};

export const useCityQueriesPagination = (): PaginationConfig => {
  const { query, page, pageSize } = useSelector<any, CityQuetiesState>(state => state.cityQueries);
  const dispatch: Dispatch = useDispatch();

  const handlePageChange = (page: number): void => {
    dispatch(cityQueriesActions.setPage(page));
  };

  const pagination: PaginationConfig = {
    pageSize,
    current: page,
    onChange: handlePageChange,
    total: query.length,
  };

  return pagination;
};
