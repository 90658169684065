import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Menu } from 'antd';

const menuStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  border: 0,
};

const HeaderNavigation: React.FC<RouteComponentProps> = ({
  match,
}) => {
  const selectedKeys = [match.path.substr(1)];

  return (
    <Menu
      selectedKeys={selectedKeys}
      mode="horizontal"
      style={menuStyle}
    >
      <Menu.Item key="query-data">
        <Link to="/query-data">
          Buscar Dados
        </Link>
      </Menu.Item>
      <Menu.Item key="query-cities">
        <Link to="/query-cities">
          Buscar Cidades
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(HeaderNavigation);
