import { TreeNode } from 'antd/lib/tree-select';

import get from 'lodash/get';
import set from 'lodash/set';
import orderBy from 'lodash/orderBy';

export const mapCitiesToTreeNodes = (citiesWithState: string[]): TreeNode[] => {
  const stateNodes: TreeNode[] = [];

  citiesWithState.forEach((cityWithState) => {
    const [city, state] = cityWithState.split(' - ');
    if (!state) return;

    if (!stateNodes.some(node => node.title === state)) {
      stateNodes.push({
        title: state,
        key: state,
        value: state,
        disableCheckbox: true,
        children: [],
      });
    }

    const stateNodeIndex: number = stateNodes.findIndex(
      node => node.title === state,
    );

    const indexToSet: number = get(
      stateNodes[stateNodeIndex],
      'children.length',
    );
    set(stateNodes[stateNodeIndex], `children[${indexToSet}]`, {
      title: city,
      key: cityWithState,
      value: cityWithState,
      label: cityWithState,
    });
  });

  return orderBy(stateNodes, 'title');
};

export const mapStatesToTreeNodes = (states: string[]): TreeNode[] => {
  return states.map(state => ({
    title: state,
    key: state,
    value: state,
  }));
};
