import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { geocodeArea } from '../../utils/maps';
import { useQueriesPagination } from '../../utils/hooks';
import { formatColumnValues } from '../../utils/columns';

import Map from './Map';
import { Spin, Pagination, message } from 'antd';

const QueryMap = () => {
  const { query, page, pageSize, loading: loadingQuery } = useSelector((state: Json) => state.queries);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAreaLatLng[]>([]);
  const pagination = useQueriesPagination();

  useEffect(
    () => {
      setLoading(true);
      const startIndex: number = (page - 1) * pageSize;
      const endIndex: number = startIndex + pageSize;
      Promise.all<IAreaLatLng>(query.political_areas.slice(startIndex, endIndex).map(geocodeArea))
        .then((data) => {
          return data.map((item) => {
            const index: number = query.political_areas.indexOf(item.area);
            const infos: IAreaInfo[] = query.columns
              .map(formatColumnValues)
              .map(col => ({
                title: col.column.replace(/##/g, ' > '),
                value: col.values[index],
              }));
            item.infos = infos;
            return item;
          });
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          message.error('Falha ao buscar coordenadas');
          setLoading(false);
        });
    },
    [query.political_areas, query.columns, page, pageSize],
  );

  return (
    <Spin spinning={loading || loadingQuery}>
      <Map
        defaultCenter={{ lat: -12.091696, lng: -51.116026 }}
        defaultZoom={5}
        data={data}
      />
      <Pagination
        style={{ textAlign: 'right' }}
        size="small"
        {...pagination}
      />
    </Spin>
  );
};

export default QueryMap;
