import { RouteComponentProps } from 'react-router';

import PublicPage from '../pages/PublicPage';

import Template from '../template/Template';
import LoginPage from '../pages/LoginPage';
// import RegisterPage from '../pages/RegisterPage';
// import RegisterConfirmationPage from '../pages/RegisterConfirmationPage';
import PasswordRecoveryPage from '../pages/PasswordRecoveryPage';
import QueryDataPage from '../pages/QueryDataPage';
import QueryCitiesPage from '../pages/QueryCitiesPage';

declare global {
  interface IPath {
    name: string;
    component: React.ComponentType<any & RouteComponentProps<any>>;
    template?: React.ComponentType;
    default?: boolean;
  }
}

export const privatePaths: IPath[] = [
  {
    name: '/query-data',
    component: QueryDataPage,
    template: Template,
    default: true,
  },
  {
    name: '/query-cities',
    component: QueryCitiesPage,
    template: Template,
    default: true,
  },
];

export const notLoggedPaths: IPath[] = [
  {
    name: '/login',
    component: LoginPage,
    default: true,
  },
  // {
  //   name: '/register',
  //   component: RegisterPage,
  // },
  // {
  //   name: '/register/:token',
  //   component: RegisterConfirmationPage,
  // },
  {
    name: '/reset-password',
    component: PasswordRecoveryPage,
  },
];

export const publicPaths: IPath[] = [
  {
    name: '/',
    component: PublicPage,
    default: true,
  },
];
