import { put } from 'redux-saga/effects';
import { message } from 'antd';

import createReduxModule from './createReduxModule';
import http from '../utils/http';
import listStatesMock from '../mocks/list-states.json';
import { normalizeError } from '../utils/error';
import { TreeNode } from 'antd/lib/tree-select';
import { mapCitiesToTreeNodes, mapStatesToTreeNodes } from '../utils/areas';

interface IState {
  cities: TreeNode[];
  states: TreeNode[];
  loading: boolean;
  error: {
    message: string,
    status?: string | number,
    isTimeout?: boolean,
  };
}

const initialState: IState = {
  cities: [],
  states: [],
  loading: false,
  error: { message: '' },
};

const reducer: IReducer<IState> = {
  getCities: (state, action) => ({
    ...state,
    loading: true,
  }),
  getCitiesSuccess: (state, action) => ({
    ...state,
    cities: action.payload,
    loading: false,
  }),
  getCitiesFailure: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  getStates: (state, action) => ({
    ...state,
    loading: true,
  }),
  getStatesSuccess: (state, action) => ({
    ...state,
    states: action.payload,
    loading: false,
  }),
  getStatesFailure: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
};

const sagas: ISagas = {
  *getCities ({ payload }) {
    try {
      const { data } = yield http.get('/list-state-cities');
      const nodes: TreeNode[] = mapCitiesToTreeNodes(data);
      yield put(reduxModule.actions.getCitiesSuccess(nodes));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao buscar filtro de cidades');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.getCitiesFailure(errorInfo));
    }
  },
  *getStates ({ payload }) {
    try {
      // const { data } = yield http.get('/list-states');
      const data = listStatesMock.data;
      const nodes: TreeNode[] = mapStatesToTreeNodes(data);
      yield put(reduxModule.actions.getStatesSuccess(nodes));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao buscar filtro de estados');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.getStatesFailure(errorInfo));
    }
  },
};

const reduxModule = createReduxModule<IState>('cities', initialState, reducer, sagas);

export const { types, actions } = reduxModule;
export default reduxModule;
