import React, { ChangeEvent, FocusEvent } from 'react';
import { TreeNode } from 'antd/lib/tree-select';

import withValidation from './withValidation';
import { FieldProps } from 'formik';

import { InputGroup, Input, Divider, Prefix } from './styles/RangeInput.styles';

interface IProps extends FieldProps {
  data: TreeNode[];
  label: string;
  type: string;
  min: number;
  max: number;
}

const RangeInput: React.FC<IProps> = ({
  field,
  form,
  label,
  type,
  min,
  max,
}) => {
  const handleChange = (type: 'min' | 'max') => (e: ChangeEvent<HTMLInputElement>): void => {
    const fieldValue = field.value || { min, max, name: label };
    const value: number = Number(e.target.value.replace(/[^0-9]/g, ''));
    fieldValue[type] = value;
    form.setFieldValue(field.name, fieldValue);
  };

  const handleBlur = (type: 'min' | 'max') => (e: FocusEvent<HTMLInputElement>): void => {
    const fieldValue = field.value || { min, max, name: label };
    if (fieldValue.min > fieldValue.max && type === 'min') fieldValue.max = fieldValue.min;
    if (fieldValue.max < fieldValue.min && type === 'max') fieldValue.min = fieldValue.max;
    if (fieldValue.min === 0 && fieldValue.max === 0) {
      fieldValue.min = undefined;
      fieldValue.max = undefined;
    }
    form.setFieldValue(field.name, fieldValue);
  };

  const prefixText: string = {
    currency: '$',
    percentage: '%',
  }[type];

  const prefix: JSX.Element | false = !!prefixText && (
    <Prefix>
      {prefixText}
    </Prefix>
  );

  return (
    <InputGroup>
      <Input
        placeholder="De"
        prefix={prefix}
        value={field.value ? field.value.min : min}
        onChange={handleChange('min')}
        onBlur={handleBlur('min')}
      />
      <Divider />
      <Input
        placeholder="Até"
        prefix={prefix}
        value={field.value ? field.value.max : max}
        onChange={handleChange('max')}
        onBlur={handleBlur('max')}
      />
    </InputGroup>
  );
};

export default withValidation(RangeInput);
