/* eslint no-template-curly-in-string: 0 */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    notType: 'Valor inválido',
    required: 'Campo é obrigatório',
  },
  string: {
    email: 'Email inválido',
    min: 'Campo deve ter ao menos ${min} caracteres',
  },
});

yup.addMethod(yup.string, 'equals', (ref, msg) => {
  return yup.mixed().test({
    name: 'equals',
    exclusive: false,
    message: msg || '${path} deve ser igual a ${reference}',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
});

yup.addMethod(yup.object, 'requireOneOf', (requiredKeys) => {
  return yup.object().test({
    name: 'requireOneOf',
    message: '${path} deve conter ao menos um dos seguintes itens: ${keys}',
    exclusive: true,
    params: { keys: requiredKeys.join(', ') },
    test: value => value && requiredKeys.some(f => !!value[f]),
  });
});

yup.addMethod(yup.object, 'notEmpty', (requiredKeys) => {
  return yup.object().test({
    name: 'notEmpty',
    message: '${path} deve conter ao menos um item selecionado',
    exclusive: true,
    test: value => value && !!Object.keys(value).length,
  });
});

export default yup;
