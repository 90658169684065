// Auth
export const JWT_EXPIRATION = 86400000;
export const MOCK_USERS = [
  {
    id: '1',
    name: 'Guilherme Eduardo',
    email: 'guilherme.eduardo@brcidades.com',
    password: '123123123',
  },
  {
    id: '2',
    name: 'AD',
    email: 'ad@brcidades.com',
    password: 'ad@teste',
  },
  {
    id: '3',
    name: 'Cinemark',
    email: 'cinemark@brcidades.com',
    password: 'cinemark@teste',
  },
  {
    id: '4',
    name: 'DSRealty',
    email: 'dsrealty@brcidades.com',
    password: 'dsrealty@teste',
  },
  {
    id: '5',
    name: 'Iguatemi',
    email: 'grupoiguatemi@brcidades.com',
    password: 'grupoiguatemi@teste',
  },
  {
    id: '6',
    name: 'Lumine',
    email: 'lumine@brcidades.com',
    password: 'lumine@teste',
  },
  {
    id: '7',
    name: 'Semma',
    email: 'semma@brcidades.com',
    password: 'semma@teste',
  },
  {
    id: '8',
    name: 'Pierotti',
    email: 'pierotti@brcidades.com',
    password: 'pierotti@teste',
  },
  {
    id: '9',
    name: 'Manzalli',
    email: 'manzalli@brcidades.com',
    password: 'manzalli@teste',
  },
  {
    id: '10',
    name: 'Geraldo Vieira',
    email: 'geraldo.vieira@brcidades.com',
    password: 'geraldo.vieira@teste',
  },
  {
    id: '11',
    name: 'Davi Lima',
    email: 'davi.lima@brcidades.com',
    password: 'davi.lima@teste',
  },
  {
    id: '12',
    name: 'Neander Souza',
    email: 'neander.souza@brcidades.com',
    password: 'neander.souza@teste',
  },
  {
    id: '13',
    name: 'Andrea Belavenuto',
    email: 'andrea.belavenuto@brcidades.com',
    password: 'andrea.belavenuto@teste',
  },
  {
    id: '14',
    name: 'Marcelo Lacerda',
    email: 'marcelo.lacerda@brcidades.com',
    password: 'marcelo.lacerda@teste',
  },
  {
    id: '15',
    name: 'Marcelo Andrade',
    email: 'marcelo.andrade@brcidades.com',
    password: 'marcelo.andrade@teste',
  },
  {
    id: '16',
    name: 'Marcia Fotossíntese',
    email: 'marcia.fotossintese@brcidades.com',
    password: 'marcia.fotossintese@teste',
  },
  {
    id: '17',
    name: 'Mariana Barros',
    email: 'mariana.barros@brcidades.com',
    password: 'mariana.barros@teste',
  },
  {
    id: '18',
    name: 'Qualibest',
    email: 'qualibest@brcidades.com',
    password: 'qualibest@teste',
  },
  {
    id: '19',
    name: 'Silvio Pires',
    email: 'silvio.pires@brcidades.com',
    password: 'silvio.pires@teste',
  },
  {
    id: '20',
    name: 'Geu',
    email: 'geu.data@brcidades.com',
    password: 'geu.data@teste',
  }
];

// Columns
export const BANNED_COLUMNS = [
  'roworder',
  '\'domicílios##favelas##2019',
  '\'população##favelas##2019',
  'Domicílios Total',
  'Escolas de Ensino Básico',
  'Escolas de Ensino Superior',
  'PIB Per Capita',
  'População Total',
  'Quantidade de Empresas',
  'Quantidade de Hospitais',
  'Quantidade de Shoppings',
  'Renda Média Familiar',
  'area_type',
  'blank',
  'município',
  'estado',
  'estado##2019',
  'blank-col',
  'cidade-estado',
  'pop_total_filter',
  'income_filter',
  'domicílios##total **##2019',
  'população##total **##2019',
  'agências bancárias##total##2010',
  'agências bancárias##classificação estado##2010',
  'agências bancárias##classificação brasil##2010',
  'agências bancárias##evolução sobre ano anterior##2010',
  'agências bancárias##total##2011',
  'agências bancárias##classificação estado##2011',
  'agências bancárias##classificação brasil##2011',
  'agências bancárias##evolução sobre ano anterior##2011',
  'agências bancárias##total##2012',
  'agências bancárias##classificação estado##2012',
  'agências bancárias##classificação brasil##2012',
  'agências bancárias##evolução sobre ano anterior##2012',
  'agências bancárias##total##2013',
  'agências bancárias##classificação estado##2013',
  'agências bancárias##classificação brasil##2013',
  'agências bancárias##evolução sobre ano anterior##2013',
  'agências bancárias##total##2014',
  'agências bancárias##classificação estado##2014',
  'agências bancárias##classificação brasil##2014',
  'agências bancárias##evolução sobre ano anterior##2014',
  'agências bancárias##total##2015',
  'agências bancárias##classificação estado##2015',
  'agências bancárias##classificação brasil##2015',
  'agências bancárias##evolução sobre ano anterior##2015',
  'agências bancárias##total##2016',
  'agências bancárias##classificação estado##2016',
  'agências bancárias##classificação brasil##2016',
  'agências bancárias##evolução sobre ano anterior##2016',
  'agências bancárias##total##2017',
  'agências bancárias##classificação estado##2017',
  'agências bancárias##classificação brasil##2017',
  'agências bancárias##evolução sobre ano anterior##2017',
  'agências bancárias##total##2018',
  'agências bancárias##classificação estado##2018',
  'agências bancárias##classificação brasil##2018',
  'agências bancárias##evolução sobre ano anterior##2018',
  'população##evolução populacional##2010',
  'população##evolução populacional##2011',
  'população##evolução populacional##2012',
  'população##evolução populacional##2013',
  'pib/renda##pib##pib total (r$/ano)##evolução sobre o ano anterior##2010',
  'pib/renda##pib##pib per capita (r$ ano)##evolução sobre o ano anterior##2010',
  'indicadores sociais##saúde##leitos hopitalares por esfera adm##evolução sobre o ano anterior##2010',
  'indicadores sociais##saúde##leitos em estabelecimentos por esfera adm##evolução sobre o ano anterior##2010',
  'indicadores sociais##saúde##hospitais por esfera adm##evolução sobre o ano anterior##2010',
  'indicadores sociais##saúde##estabelecimentos por esfera adm##evolução sobre o ano anterior##2010',
  'indicadores sociais##educação##matrículas no ensino superior##evolução sobre o ano anterior##2010',
  'indicadores sociais##educação##matrículas no ensino básico##evolução sobre o ano anterior##2010',
  'indicadores sociais##educação##escolas de ensino superior##evolução sobre o ano anterior##2010',
  'indicadores sociais##educação##escolas de ensino básico##evolução sobre o ano anterior##2010',
  'indicadores sociais##educação##cursos em escolas de ensino superior##evolução sobre o ano anterior##2010',
  'frota de veículos##evolução sobre o ano anterior##2010',
  'empresa/empregos##empresas##evolução sobre o ano anterior##2010',
  'empresa/empregos##empregos##evolução sobre o ano anterior##2010',
];

export const COLUMN_TOOLTIPS = {
};

// Charts
// Colors from Dutch Palette by Jeroen Van Eerden
export const CHART_COLORS = [
  '#0652DD',
  '#006266',
  '#9980FA',
  '#1B1464',
  '#009432',
  '#6F1E51',
  '#ED4C67',
  '#FFC312',
  '#FDA7DF',
  '#B53471',
  '#C4E538',
  '#833471',
  '#12CBC4',
  '#EE5A24',
  '#F79F1F',
  '#A3CB38',
  '#1289A7',
  '#D980FA',
  '#EA2027',
  '#5758BB',
];
