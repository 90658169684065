import React from 'react';

import { Container, Sources } from './styles/Footer.styles';

interface IProps {
  children?: React.ReactNode;
}

const Footer: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
        <Sources>GEU/IBGE/PNAD/PNUD/DENATRAN/INEP/CNES/RAIS/ABRASCE/POF</Sources>
        {children}
    </Container>
  );
};

export default Footer;
