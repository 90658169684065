import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

interface IFilterContainerProps {
  isPlaceholderVisible?: boolean;
}

export const FilterContainer = styled.div<IFilterContainerProps>`
  &, .ant-select { width: 100%; max-width: 75vw; }
  .ant-select-selection__choice { display: none; }
  .ant-select-search__field__placeholder {
    display: ${props => props.isPlaceholderVisible ? 'block !important' : 'none'};
  }
  .ant-select-search,
  .ant-select-search__field { width: 100% !important; }
`;

export const FilterListContainer = styled.div`
  padding-bottom: 2rem;
`;

export const FilterListContent = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 2rem;
  width: 100%;
`;

export const FilterListPlaceholder = styled.span`
  position: absolute;
  top: 50%;
  right: 9px;
  left: 12px;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
`;

export const FilterListItem = styled.div`
  display: inline-block;
  padding: .25rem .5rem;
  .ant-select {
    min-width: 7em;
    width: fit-content;
    text-transform: capitalize;
    .ant-select-selection__clear {
      opacity: 1;
      right: 9px;
    }
  }
`;

export const FilterListLabel = styled.div`
  display: inline-block;
  margin-right: .75rem;
  text-transform: capitalize;
  ::after { content: ':'; }
`;

export const Button = styled(AntdButton)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: left;
  width: 100%;
`;

interface IButtonTitleProps {
  hasSubtitle?: boolean;
}

export const ButtonTitle = styled.span<IButtonTitleProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  width: ${props => props.hasSubtitle ? '60%' : '100%'};
`;

export const ButtonSubtitle = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-size: .65rem;
  margin-left: .5rem;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40%;
`;

export const MaxTagPlaceholder = styled.span`
  cursor: pointer;
`;
