import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Collapse } from 'antd';
import { Container, Header, WarningIcon, HeaderTitle, Warnings, Warning, WarningTitle } from './styles/QueryWarningsCollapse.styles';

const key: string = 'query-warnings-collapse';

const QueryWarningsCollapse: React.FC = () => {
  const { query } = useSelector((state: Json) => state.queries);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = (): void => setIsOpen(!isOpen);

  if (!query.columns_with_no_data.length) return null;

  const header: JSX.Element = (
    <Header>
      <WarningIcon />
      <HeaderTitle>
        Os seguintes campos não possuem resultados
      </HeaderTitle>
    </Header>
  );

  const warnings: JSX.Element[] = query.columns_with_no_data.map(col =>
    <Warning key={col}>
      <WarningTitle>
        {col.replace(/##/g, ' > ')}
      </WarningTitle>
    </Warning>,
  );

  return (
    <Container>
      <Collapse
        activeKey={isOpen ? key : ''}
        bordered={false}
        expandIconPosition="right"
        onChange={toggle}
      >
        <Collapse.Panel
          key={key}
          header={header}
        >
          <Warnings>
            {warnings}
          </Warnings>
        </Collapse.Panel>
      </Collapse>
    </Container>
  );
};

export default QueryWarningsCollapse;
