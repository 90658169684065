import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../store/cityQueries';
import { useCityQueriesPagination } from '../../utils/hooks';
import { mapQueryToColumns, mapQueryToData } from '../../utils/cityQueries';

import { Spin, Table } from 'antd';
import { Container } from './styles/Table.styles';

const QueryCitiesTable: React.FC = () => {
  const { query, loading } = useSelector<any, IState>(state => state.cityQueries);
  const pagination = useCityQueriesPagination();

  return (
    <Spin spinning={loading}>
      <Container>
        <Table
          bordered={true}
          columns={mapQueryToColumns(query)}
          dataSource={mapQueryToData(query)}
          locale={{ emptyText: 'Os seus resultados aparecerão aqui' }}
          pagination={pagination}
          scroll={{ x: 'max-content' }}
          size="middle"
        />
      </Container>
    </Spin>
  );
};

export default QueryCitiesTable;
