import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  .recharts-tooltip-wrapper {
    z-index: 1;
  }
`;

export const Chart = styled.div`

`;

export const ChartTitle = styled.p`
  color: #1890ff;
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: 3rem;
  text-transform: capitalize;
`;

export const PlaceholderContainer = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
`;
