import { put, delay, select } from 'redux-saga/effects';
import { message } from 'antd';

import http from '../utils/http';
import listFiltersMock from '../mocks/list-filters.json';
import createReduxModule from './createReduxModule';
import { normalizeError } from '../utils/error';
import { filterNonCityFromQuery } from '../utils/cityQueries';

export interface IState {
  formValues?: any;
  filters: [];
  query: ICitiesQuery;
  rawQuery: ICitiesQuery;
  showOnlyCities: boolean;
  meta: ICitiesQueryMeta;
  cities_count?: number | string;
  year?: number | string;
  loading: boolean;
  page: number;
  pageSize: number;
  error: {
    message: string;
    status?: string | number;
    isTimeout?: boolean;
  };
}

const initialState: IState = {
  filters: [],
  query: [],
  rawQuery: [],
  showOnlyCities: false,
  meta: {},
  loading: false,
  page: 1,
  pageSize: 5,
  error: { message: '' },
};

const reducer: IReducer<IState> = {
  fetchFilters: (state, action) => ({
    ...state,
    loading: true,
  }),
  fetchFiltersSuccess: (state, action) => ({
    ...state,
    filters: action.payload,
    loading: false,
  }),
  fetchFiltersFailure: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  query: (state, action) => ({
    ...state,
    loading: true,
  }),
  querySuccess: (state, action) => ({
    ...state,
    query: action.payload.data,
    meta: action.payload.meta,
    loading: false,
  }),
  queryFailure: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  clearQuery: (state, action) => ({
    ...state,
    query: initialState.query,
    meta: initialState.meta,
  }),
  setPage: (state, action) => ({
    ...state,
    page: action.payload,
  }),
  setFormValues: (state, action) => ({
    ...state,
    formValues: action.payload,
  }),
  setShowOnlyCities: (state, action) => ({
    ...state,
    showOnlyCities: action.payload,
  }),
  setQuery: (state, action) => ({
    ...state,
    query: action.payload,
  }),
  setRawQuery: (state, action) => ({
    ...state,
    rawQuery: action.payload,
  }),
};

const sagas: ISagas = {
  *fetchFilters({ payload }) {
    try {
      // const { data } = yield http.get('/list-filters');
      yield delay(1000);
      const data = listFiltersMock.filters;
      yield put(reduxModule.actions.fetchFiltersSuccess(data));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao buscar filtros');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.fetchFiltersFailure(errorInfo));
    }
  },
  *query({ payload }) {
    try {
      const { data: queryDataAndMeta } = yield http.post('/query-filter', payload);
      const { data, meta } = queryDataAndMeta;
      const { showOnlyCities } = yield select(store => store.cityQueries);
      const queryData: ICitiesQuery = showOnlyCities ? filterNonCityFromQuery(data) : data;
      yield put(reduxModule.actions.setRawQuery(data));
      yield put(reduxModule.actions.querySuccess({ meta, data: queryData }));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao realizar busca');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.queryFailure(errorInfo));
    }
  },
  *setShowOnlyCities({ payload: showOnlyCities }) {
    const { query, rawQuery } = yield select(store => store.cityQueries);

    if (showOnlyCities) {
      const queryWithOnlyCities: ICitiesQuery = filterNonCityFromQuery(query);

      yield put(reduxModule.actions.setQuery(queryWithOnlyCities));
    } else {
      yield put(reduxModule.actions.setQuery(rawQuery));
    }
  },
};

const reduxModule = createReduxModule<IState>(
  'cityQueries',
  initialState,
  reducer,
  sagas,
);

export const { types, actions } = reduxModule;
export default reduxModule;
