import React from 'react';
import { put, select, delay } from 'redux-saga/effects';
import { message, notification, Icon } from 'antd';

import createReduxModule from './createReduxModule';
import history from '../routes/history';
import http, { removeToken } from '../utils/http';
import { normalizeError } from '../utils/error';
import { persistor } from './';
import { MOCK_USERS } from '../utils/constants';

interface IState {
  logged: boolean;
  user: IUser;
  loading: boolean;
  error: {
    message: string,
    status?: string | number,
    isTimeout?: boolean,
  };
}

const initialState: IState = {
  logged: false,
  user: {
    id: '',
    name: '',
    email: '',
  },
  loading: false,
  error: { message: '' },
};

const reducer: IReducer<IState> = {
  login: (state, action) => ({
    ...state,
    loading: true,
  }),
  loginSuccess: (state, action) => ({
    ...state,
    user: action.payload,
    loading: false,
    logged: true,
  }),
  loginFailure: (state, action) => ({
    ...state,
    user: action.payload,
    loading: false,
  }),
  logout: (state, action) => ({
    ...state,
    loading: false,
    logged: false,
    user: initialState.user,
  }),
  logoutSuccess: (state, action) => ({
    ...state,
    loading: false,
    logged: false,
  }),
  logoutFailure: (state, action) => ({
    ...state,
    loading: false,
  }),
  register: (state, action) => ({
    ...state,
    loading: true,
  }),
  registerSuccess: (state, action) => ({
    ...state,
    loading: false,
  }),
  registerFailure: (state, action) => ({
    ...state,
    loading: false,
  }),
  requestPasswordRecovery: (state, action) => ({
    ...state,
    loading: true,
  }),
  requestPasswordRecoverySuccess: (state, action) => ({
    ...state,
    loading: false,
  }),
  requestPasswordRecoveryFailure: (state, action) => ({
    ...state,
    loading: false,
  }),
  recoverPassword: (state, action) => ({
    ...state,
    loading: true,
  }),
  recoverPasswordSuccess: (state, action) => ({
    ...state,
    loading: false,
  }),
  recoverPasswordFailure: (state, action) => ({
    ...state,
    loading: false,
  }),
  changePassword: (state, action) => ({
    ...state,
    loading: true,
  }),
  changePasswordSuccess: (state, action) => ({
    ...state,
    loading: false,
  }),
  changePasswordFailure: (state, action) => ({
    ...state,
    loading: false,
  }),
};

const sagas: ISagas = {
  *login({ payload }) {
    try {
      // const req = {
      //   user: payload,
      // };
      //
      // const { data } = yield http.post('/login', req)
      //   .then(mapHeadersToData('authorization'));
      // setToken(data.authorization);
      // delete data.authorization;

      yield delay(1500);

      const user: IUser | undefined = MOCK_USERS.find(user => user.email === payload.email);

      if (!user || user.password !== payload.password) {
        throw new Error('Email e/ou Senha Inválida');
      }

      yield put(reduxModule.actions.loginSuccess(user));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao autenticar usuário');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.loginFailure(errorInfo));
    }
  },
  *logout({ payload }) {
    try {
      removeToken();
      history.push('/login');
      yield put(reduxModule.actions.logoutSuccess());
      yield http.post('/logout', payload);
      yield persistor.purge();
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao sair');
      yield put(reduxModule.actions.logoutFailure(errorInfo));
    }
  },
  *register({ payload }) {
    try {
      const { data } = yield http.post('/signup', payload);
      history.push('/login');
      message.success('Usuário registrado com sucesso');
      yield put(reduxModule.actions.registerSuccess(data));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao registrar usuário');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.registerFailure(errorInfo));
    }
  },
  *requestPasswordRecovery({ payload }) {
    try {
      const req = {
        user: payload,
      };
      const { data } = yield http.post('/password', req);
      history.push('/login');
      notification.open({
        message: 'Recuperação de senha',
        description: `Email para recuperação de senha enviado.
        Verifique sua caixa de entrada para continuar.`,
        icon: <Icon type="mail" style={{ color: '#108ee9' }} />,
        duration: null,
      });
      yield put(reduxModule.actions.requestPasswordRecoverySuccess(data));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao solicitar recuperação de senha');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.requestPasswordRecoveryFailure(errorInfo));
    }
  },
  *recoverPassword({ payload }) {
    try {
      const req = {
        user: {
          password: payload.password,
          reset_password_token: payload.token,
        },
      };

      history.push('/login');
      const { data } = yield http.put('/password', req);
      message.success('Senha recuperada com sucesso');
      yield put(reduxModule.actions.recoverPasswordSuccess(data));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao recuperar senha');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.recoverPasswordFailure(errorInfo));
    }
  },
  *changePassword({ payload }) {
    try {
      const user = yield select(state => state.auth);

      const req = {
        user: {
          password: payload.password,
        },
      };

      const { data } = yield http.put(`/users/${user.id}`, req);
      message.success('Senha modificada com sucesso');
      yield put(reduxModule.actions.changePasswordSuccess(data));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao modificar senha');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.changePasswordFailure(errorInfo));
    }
  },
};

const reduxModule = createReduxModule<IState>('auth', initialState, reducer, sagas);

export const { types, actions } = reduxModule;
export default reduxModule;
