import styled from 'styled-components';

export const Table = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.75);
`;

interface ColumnProps {
  header?: boolean;
}

export const Column = styled.div<ColumnProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  :not(:last-of-type) {
    border-right: 1px solid #e8e8e8;
  }
  ${props => props.header && `
    flex: unset;
  `}
`;

interface CellProps {
  header?: boolean;
  color?: string;
}

export const Cell = styled.div<CellProps>`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  padding: 2px 5px;
  min-height: 1.6rem;
  :not(:last-of-type) {
    border-bottom: 1px solid #e8e8e8;
  }
  ${props => props.header && `
    font-weight: bold;
    text-transform: capitalize;
    max-width: unset;
    text-overflow: unset;
  `}
  ${props => props.color && `color: ${props.color};`}
`;
