import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import yup from '../../utils/yup';
import { ObjectSchema } from 'yup';
import { actions } from '../../store/auth';

import Form from './Form';
import EmailInput from '../inputs/EmailInput';
import { Field } from 'formik';

interface IValidation {
  email: string;
}

const validationSchema: ObjectSchema<IValidation> = yup.object().shape({
  email: yup.string().email().required(),
});

const RequestPasswordRecoveryForm: React.FC = (props) => {
  const { loading } = useSelector((state: Json) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit: (values: Json) => void = (values) => {
    dispatch(actions.requestPasswordRecovery(values));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      loading={loading}
    >
      <Field
        name="email"
        label="Email"
        component={EmailInput}
      />
    </Form>
  );
};

export default RequestPasswordRecoveryForm;
