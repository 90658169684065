import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { geocodeArea } from '../../utils/maps';
import { useCityQueriesPagination } from '../../utils/hooks';
import { formatColumnValue } from '../../utils/columns';

import Map from './Map';
import { Spin, Pagination, message } from 'antd';

const QueryMap = () => {
  const { query, page, pageSize, loading: loadingQuery } = useSelector((state: Json) => state.cityQueries);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAreaLatLng[]>([]);
  const pagination = useCityQueriesPagination();

  useEffect(
    () => {
      setLoading(true);
      const startIndex: number = (page - 1) * pageSize;
      const endIndex: number = startIndex + pageSize;

      Promise.all<IAreaLatLng>(
        query.slice(startIndex, endIndex)
          .map(item => geocodeArea(item.political_area, item)),
      )
        .then((data) => {
          return data.map((item) => {
            const itemPayload: ICitiesQueryItem = { ...item.payload };
            delete itemPayload.political_area;
            delete itemPayload.parent_area;
            delete itemPayload.area_type;
            delete itemPayload.index;

            const infos: IAreaInfo[] = Object.entries(itemPayload)
              .map(([key, val]) => {
                const { value, type, year } = val;
                let title: string = key;
                if (!!year) title += ` (${year})`;

                return {
                  title,
                  value: formatColumnValue(value, type),
                };
              });

            item.infos = infos;
            return item;
          });
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          message.error('Falha ao buscar coordenadas');
          setLoading(false);
        });
    },
    [query, page, pageSize],
  );

  return (
    <Spin spinning={loading || loadingQuery}>
      <Map
        defaultCenter={{ lat: -12.091696, lng: -51.116026 }}
        defaultZoom={5}
        data={data}
      />
      <Pagination
        style={{ textAlign: 'right' }}
        size="small"
        {...pagination}
      />
    </Spin>
  );
};

export default QueryMap;
