import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../store/auth';

import { Link } from 'react-router-dom';

const SubmitButton: React.FC = (props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(actions.logout());
  };

  return (
    <Link
      to="/login"
      onClick={handleClick}
    >
      Logout
    </Link>
  );
};

export default SubmitButton;
