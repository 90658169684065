export const normalize = (input: string): string => {
  return input
    .toLowerCase()
    .replace(/[àáâã]/g, 'a')
    .replace(/[éê]/g, 'e')
    .replace(/[í]/g, 'i')
    .replace(/[óôõ]/g, 'o')
    .replace(/[ú]/g, 'u')
    .replace(/[ç]/g, 'c');
};
