import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { combineReducers, createStore, compose, applyMiddleware, Reducer } from 'redux';
import { persistStore, persistReducer, PersistConfig, Persistor } from 'redux-persist';
import { all } from 'redux-saga/effects';
import { handleToken } from './middlewares';

import auth from './auth';
import queries from './queries';
import columns from './columns';
import areas from './areas';
import cityQueries from './cityQueries';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

// Reducers
const rootReducer = combineReducers({
  auth: auth.reducer,
  columns: columns.reducer,
  queries: queries.reducer,
  areas: areas.reducer,
  cityQueries: cityQueries.reducer,
});

// Sagas
export function* rootSaga() {
  yield all([
    auth.runSagas(),
    queries.runSagas(),
    columns.runSagas(),
    areas.runSagas(),
    cityQueries.runSagas(),
  ]);
}

const composeEnhancers =
typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
: compose;

const persistConfig: PersistConfig = {
  storage,
  key: 'root',
  whitelist: ['auth'],
};

const persistedReducer: Reducer = persistReducer(persistConfig, rootReducer);
const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, handleToken));
const store = createStore(persistedReducer, initialState, enhancer);

sagaMiddleware.run(rootSaga);

export const persistor: Persistor = persistStore(store);
export default store;
