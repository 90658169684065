import React from 'react';
import { useSelector } from 'react-redux';

import ChangePasswordModal from '../modals/ChangePasswordModal';
import LogoutButton from '../buttons/LogoutButton';
import { Dropdown, Avatar, Menu } from 'antd';
import { UserInfo, Name } from './styles/UserOptions.styles';

import avatarPlaceholder from '../../assets/avatar-placeholder.svg';

const UserOptions: React.FC = () => {
  const { name } = useSelector((state: any) => state.auth.user);

  const menu: JSX.Element = (
    <Menu>
      <Menu.Item>
        <ChangePasswordModal />
      </Menu.Item>
      <Menu.Item>
        <LogoutButton />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
    >
      <UserInfo>
        <Name>
          {name}
        </Name>
        <Avatar
          src={avatarPlaceholder}
          size="large"
        />
      </UserInfo>
    </Dropdown>
  );
};

export default UserOptions;
