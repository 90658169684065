import styled from 'styled-components';

export const Icon = styled.img`
  width: 100%;
  height: auto;
`;

interface BackgroundProps {
  src: string;
}

export const Background = styled.div<BackgroundProps>`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${props => props.src}');
  background-position: top right;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;
