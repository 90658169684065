import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';

import { IState } from '../../store/queries';
import { useQueriesPagination } from '../../utils/hooks';
import { queryToCityChartData, queryToFilterChartData } from '../../utils/charts';
import { CHART_COLORS } from '../../utils/constants';

import ChartTooltip from '../tooltips/ChartTooltip';
import { Spin, Radio, Pagination } from 'antd';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, TooltipProps } from 'recharts';
import { Container, Chart, ChartTitle, PlaceholderContainer } from './styles/Charts.styles';

const QueryCharts = () => {
  const { query, page: cityChartsPage, pageSize, loading } = useSelector<any, IState>(state => state.queries);
  const [filterChartsPage, setFilterChartsPage] = useState<number>(1);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [activeLine, setActiveLine] = useState<{ key: string, i: number }>({ key: '', i: -1 });
  const [chartType, setChartType] = useState<'city' | 'filter'>('city');
  const pagination = useQueriesPagination();
  const filterChartsCount = useRef<number>(0);
  const container = useRef(null);

  useLayoutEffect(
    () => {
      if (!container.current) return;
      // @ts-ignore: Object is possibly 'null'
      const width: number = container.current.offsetWidth;
      // @ts-ignore: Object is possibly 'null'
      const height: number = container.current.offsetHeight;

      setDimensions({ width, height });
    },
    [],
  );

  useEffect(() => { setFilterChartsPage(1); }, [query]);

  const handleRadioChange = (e: RadioChangeEvent): void => {
    setChartType(e.target.value);
  };

  const handleMouseLeave = (): void => setActiveLine({ key: '', i: -1 });

  const renderTooltip = (isSingleYear: boolean, title?: string) => (props: TooltipProps): JSX.Element => (
    <ChartTooltip
      {...props}
      activeItem={activeLine}
      isSingleYear={isSingleYear}
      title={title}
    />
  );

  const cityChartsStartIndex: number = (cityChartsPage - 1) * pageSize;
  const cityChartsEndIndex: number = cityChartsStartIndex + pageSize;

  const getCityCharts = (query: IQuery): JSX.Element[] => {
    return query.political_areas
      .slice(cityChartsStartIndex, cityChartsEndIndex)
      .map((area, i) => {
        const index: number = query.political_areas.indexOf(area);
        const data: object[] = queryToCityChartData(query, index);

        const keys = Object.keys(data.reduce((acc, cur) => ({ ...acc, ...cur }), {}));
        keys.splice(keys.indexOf('year'), 1);
        const lines: (JSX.Element | null)[] = keys.map((key, j) => {
          const handleMouseOver = (): void => setActiveLine({ key, i });
          const isActiveLine: boolean = activeLine.key === key && activeLine.i === i;

          return (
            <Line
              key={key}
              type="monotone"
              dataKey={`${key}.value`}
              name={key}
              stroke={CHART_COLORS[j % CHART_COLORS.length]}
              opacity={isActiveLine ? 1 : 0.5}
              strokeWidth={isActiveLine ? 7 : 5}
              onMouseOver={handleMouseOver}
            />
          );
        });

        return (
          <Chart key={area}>
            <ChartTitle>{area}</ChartTitle>
            <LineChart
              onMouseLeave={handleMouseLeave}
              width={dimensions.width}
              height={300}
              data={data}
              margin={{ top: 5, bottom: 5, left: 50, right: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year.label" />
              <YAxis />
              <Tooltip content={renderTooltip(data.length === 1, area)} />
              {lines}
            </LineChart>
          </Chart>
        );
      });
  };

  const filterChartsStartIndex: number = (filterChartsPage - 1) * pageSize;
  const filterChartsEndIndex: number = filterChartsStartIndex + pageSize;

  const getFilterCharts = (query: IQuery): JSX.Element[] => {
    const data: [string, object[]][] = queryToFilterChartData(query);
    filterChartsCount.current = data.length;

    return data
      .slice(filterChartsStartIndex, filterChartsEndIndex)
      .map(([filter, data], i) => {
        const lines: (JSX.Element | null)[] = query.political_areas.map((key, j) => {
          const handleMouseOver = (): void => setActiveLine({ key, i });
          const isActiveLine: boolean = activeLine.key === key && activeLine.i === i;

          return (
            <Line
              key={key}
              type="monotone"
              dataKey={`${key}.value`}
              name={key}
              stroke={CHART_COLORS[j % CHART_COLORS.length]}
              opacity={isActiveLine ? 1 : 0.5}
              strokeWidth={isActiveLine ? 7 : 5}
              onMouseOver={handleMouseOver}
            />
          );
        });

        return (
          <Chart key={filter}>
            <ChartTitle>{filter}</ChartTitle>
            <LineChart
              onMouseLeave={handleMouseLeave}
              width={dimensions.width}
              height={300}
              data={data}
              margin={{ top: 5, bottom: 5, left: 50, right: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year.label" />
              <YAxis />
              <Tooltip content={renderTooltip(data.length === 1)} />
              {lines}
            </LineChart>
          </Chart>
        );
      });
  };

  const charts: JSX.Element[] | false = !!query.columns && !!query.columns.length && {
    city: getCityCharts(query),
    filter: getFilterCharts(query),
  }[chartType];

  const radio: JSX.Element = (
    <Radio.Group
      defaultValue={chartType}
      onChange={handleRadioChange}
      buttonStyle="solid"
      style={{ marginBottom: '1rem' }}
    >
      <Radio.Button value="city">Mostrar Gráficos Por Cidade</Radio.Button>
      <Radio.Button value="filter">Mostrar Gráficos Por Filtro</Radio.Button>
    </Radio.Group>
  );

  const placeholder: JSX.Element = (
    <PlaceholderContainer>
      Os seus gráficos aparecerão aqui
    </PlaceholderContainer>
  );

  const cityPagination: JSX.Element | false = chartType === 'city' && (
    <Pagination
      style={{ textAlign: 'right' }}
      size="small"
      {...pagination}
    />
  );

  const filterPagination: JSX.Element | false = chartType === 'filter' && (
    <Pagination
      style={{ textAlign: 'right' }}
      size="small"
      current={filterChartsPage}
      onChange={setFilterChartsPage}
      total={filterChartsCount.current}
      pageSize={pageSize}
    />
  );

  return (
    <Spin spinning={loading}>
      <Container ref={container}>
        {!!charts && !!charts.length && radio}
        {!!charts && !!charts.length ? charts : placeholder}
        {cityPagination}
        {filterPagination}
      </Container>
    </Spin>
  );
};

export default QueryCharts;
