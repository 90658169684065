import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

const PublicPage: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const { logged } = useSelector((state: any) => state.auth);

  !logged && history.push('/login');
  return null;
};

export default PublicPage;
