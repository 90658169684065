import styled from 'styled-components';

export const Container = styled.footer`
    position: fixed;
    left: 0;
    bottom: 0;
    margin: .5rem 1rem;
`;

export const Sources = styled.span`
    opacity: 0.5;
    font-size: .8rem;
`;
