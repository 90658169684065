/*global google*/
import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow } from 'react-google-maps';

import withMap from './withMap';

import ShowMoreCollapse from '../collapses/ShowMoreCollapse';
import { Info, InfoTitle, InfoItem, InfoItemTitle, InfoItemValue } from './styles/Map.style';

interface IProps {
  data: IAreaLatLng[];
  defaultCenter?: google.maps.LatLng | google.maps.LatLngLiteral;
  defaultZoom?: number;
}

const Map: React.FC<IProps> = ({
  data,
  defaultCenter,
  defaultZoom,
}) => {
  const [hiddenInfos, setHiddenInfos] = useState<number[]>([]);
  const mapRef = useRef<GoogleMap>(null);
  const markersRef = useRef<Marker[]>([]);

  useEffect(() => { updateBounds(); }, [data]);

  const updateBounds = (): void => {
    if (!markersRef.current.length) return;
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    const positions: google.maps.LatLng[] = markersRef.current
      .filter(Boolean).map(marker => marker.getPosition());
    positions.forEach(position => bounds.extend(position));
    mapRef.current && mapRef.current.fitBounds(bounds);
  };

  const markers: JSX.Element[] = data.map((item, i) => {
    const itemInfos: JSX.Element[] | false = !!item.infos
      && item.infos.map((info, i) => (
          <InfoItem key={i}>
            <InfoItemTitle>{info.title}</InfoItemTitle>
            <InfoItemValue>{info.value}</InfoItemValue>
          </InfoItem>
      ));

    const maxItems: number = 2;
    const firstItemInfos: JSX.Element[] | false = !!itemInfos && itemInfos.slice(0, maxItems);
    const otherItemInfos: JSX.Element | false = !!itemInfos && itemInfos.length > maxItems && (
      <ShowMoreCollapse>
        {itemInfos.slice(maxItems)}
      </ShowMoreCollapse>
    );

    const show = (): void => {
      const hidden: number[] = [...hiddenInfos];
      hidden.splice(hidden.indexOf(i), 1);
      setHiddenInfos(hidden);
    };

    const hide = (): void => {
      const hidden: number[] = [...hiddenInfos];
      hidden.push(i);
      setHiddenInfos(hidden);
    };

    const infoWindow: JSX.Element | false = !hiddenInfos.includes(i) && (
      <InfoWindow onCloseClick={hide}>
        <Info>
          <InfoTitle>{item.area}</InfoTitle>
          {firstItemInfos}
          {otherItemInfos}
        </Info>
      </InfoWindow>
    );

    return (
      <Marker
        key={i}
        position={{ lat: item.lat, lng: item.lng }}
        ref={(ref) => { if (ref) markersRef.current[i] = ref; }}
        onClick={show}
      >
        {infoWindow}
      </Marker>
    );
  });

  return (
    <GoogleMap
      ref={mapRef}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      options={{ streetViewControl: false }}
    >
      {markers}
    </GoogleMap>
  );
};

export default withMap<IProps>(Map);
