import React from 'react';

import get from 'lodash/get';

import { Form } from 'antd';
import { FieldProps } from 'formik';

interface IProps {
  label?: string;
}

const withValidation = <P extends object>(Component: React.ComponentType<P>) =>
  (props: P & IProps & FieldProps) => {
    const {
      form,
      field,
    } = props;

    const error = get(form.errors, field.name);

    return (
      <Form.Item
        label={props.label}
        hasFeedback={!!error}
        validateStatus={!!error ? 'error' : ''}
        help={error}
      >
        <Component {...props} />
      </Form.Item>
    );
  };

export default withValidation;
