import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import yup from '../../utils/yup';
import { ObjectSchema } from 'yup';
import { message } from 'antd';
import { actions } from '../../store/cityQueries';

import Form from './Form';
import RangeInput from '../inputs/RangeInput';
import { Field } from 'formik';
import {
  FiltersLabel,
  RangeFiltersContainer,
} from './styles/QueryCitiesForm.styles';
import StatesInput from '../inputs/StatesInput';

interface IProps {
  style?: React.CSSProperties;
}

interface IValidation {
  filters: {
    name: string;
    min: number;
    max: number;
  }[];
  states: string[] | null;
}
const validationSchema: ObjectSchema<IValidation> = yup.object().shape({
  filters: yup.array(
    yup.object().shape({
      name: yup.string(),
      min: yup.number(),
      max: yup.number(),
    }),
  ),
  states: yup.array(
    yup.string(),
  ),
});

const QueryCitiesForm: React.FC<IProps> = ({
  style,
}) => {
  const { formValues, loading, filters } = useSelector((state: Json) => state.cityQueries);
  const dispatch = useDispatch();

  useEffect(() => { dispatch(actions.fetchFilters()); }, [dispatch]);

  const handleSubmit: (values: Json) => void = (values) => {
    const filters = values.filters.filter(Boolean);

    const payload: IValidation = { ...values as IValidation };
    if (!payload.states || !payload.states.length) payload.states = null;
    payload.filters = filters.filter(filter => filter.min !== undefined && filter.max !== undefined);

    if (!values.filters || !filters.length) {
      return message.error('Ao menos um filtro deve ser preenchido');
    }

    dispatch(actions.query(payload));
  };

  const handleReset = (): void => {
    dispatch(actions.clearQuery());
  };

  const handleValidation = (values: Json | undefined): void => {
    dispatch(actions.setFormValues(values));
  };

  const resetValues = { filters: Array(filters.length).fill(undefined) };

  const rangeFilters: JSX.Element[] = filters.map((filter, i) =>
    <Field
      key={filter.name}
      name={`filters[${i}]`}
      label={filter.name}
      component={RangeInput}
      type={filter.type}
    />,
  );

  return (
    <Form
      style={style}
      onSubmit={handleSubmit}
      onReset={handleReset}
      loading={loading}
      validationSchema={validationSchema}
      resetValues={resetValues}
      initialValues={formValues || resetValues}
      enableReinitialize={true}
      allowClear={true}
      submitButtonText="Buscar"
      validate={handleValidation}
    >
      <FiltersLabel>Filtros:</FiltersLabel>
      <RangeFiltersContainer>
        {rangeFilters}
      </RangeFiltersContainer>
      <Field
        name="states"
        label="Estados"
        inputLabel="Filtrar por Estado"
        component={StatesInput}
      />
    </Form>
  );
};

export default QueryCitiesForm;
