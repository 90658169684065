import React from 'react';
import { RouteComponentProps } from 'react-router';

import ShowOnlyCitiesCheckbox from '../components/checkbox/ShowOnlyCitiesCheckbox';
import QueryDataForm from '../components/forms/QueryDataForm';
import QueryWarningsCollapse from '../components/collapses/QueryWarningsCollapse';
import QueryTable from '../components/tables/QueryTable';
import QueryCharts from '../components/charts/QueryCharts';
import QueryMap from '../components/maps/QueryMap';
import { Tabs, Icon } from 'antd';
import { Page } from './styles/Page.styles';

const QueryDataPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <Page>
      <QueryDataForm style={{ marginBottom: '2rem' }} />
      <QueryWarningsCollapse />
      <Tabs tabBarExtraContent={<ShowOnlyCitiesCheckbox />}>
        <Tabs.TabPane
          key="table"
          tab={<span><Icon type="table" />Tabela</span>}
        >
          <QueryTable />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="charts"
          tab={<span><Icon type="line-chart" />Gráficos</span>}
        >
          <QueryCharts />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="map"
          tab={<span><Icon type="environment" />Mapa</span>}
        >
          <QueryMap />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

export default QueryDataPage;
