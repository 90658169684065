import React from 'react';
import { TreeNode } from 'antd/lib/tree-select';

import SelectFilter from '../filters/SelectFilter';
import withValidation from './withValidation';
import { FieldProps } from 'formik';

interface IProps extends FieldProps {
  label?: string;
  data: TreeNode[];
}

const ColumnInput: React.FC<IProps> = ({
  data,
  field,
  form,
  label,
}) => {
  const handleChange: (value: string[]) => void = (value) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <SelectFilter
      data={data}
      label={label}
      value={field.value}
      onChange={handleChange}
    />
  );
};

export default withValidation(ColumnInput);
