import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from '../../store/areas';

import SelectFilter from '../filters/SelectFilter';
import withValidation from './withValidation';
import { FieldProps } from 'formik';
import { TreeNode } from 'antd/lib/tree-select';

interface IProps extends FieldProps {
  label?: string;
  states?: string;
}

const CitiesInput: React.FC<IProps> = ({
  field,
  form,
  label,
  states,
}) => {
  const { cities: nodes, loading } = useSelector((state: Json) => state.areas);
  const dispatch = useDispatch();

  const data: TreeNode[] = !!states && !!states.length
    ? nodes.filter(node => states.includes(node.key))
    : nodes;

  useEffect(() => { dispatch(actions.getCities()); }, [dispatch]);

  const handleChange: (value: string[]) => void = (value) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <SelectFilter
      data={data}
      label={label}
      loading={loading}
      value={field.value}
      onChange={handleChange}
      filterByTitle={true}
    />
  );
};

export default withValidation(CitiesInput);
