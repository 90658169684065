import React from 'react';
import { RouteComponentProps } from 'react-router';

import queryString, { ParsedQuery } from 'query-string';

import PasswordRecoveryForm from '../components/forms/PasswordRecoveryForm';
import { Link } from 'react-router-dom';
import { Page, Container, Row, Title } from './styles/Page.styles';

const PasswordRecoveryPage: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const parsed: ParsedQuery = queryString.parse(location.search);
  const token: string = `${parsed.reset_password_token}`;

  return (
    <Page>
      <Container center={true}>
        <Title>Recuperar Senha</Title>
        <PasswordRecoveryForm token={token} />
        <Row>
          <Link to="/login">Login</Link>
        </Row>
      </Container>
    </Page>
  );
};

export default PasswordRecoveryPage;
