import styled from 'styled-components';

export const MapElement = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 25rem;
`;

export const Info = styled.div`
`;

export const InfoTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: .5rem;
`;

export const InfoItem = styled.div`
  display: block;
`;

export const InfoItemTitle = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  ::after { content: ': '; }
`;

export const InfoItemValue = styled.span`

`;
