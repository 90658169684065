import React from 'react';

import { Formik, FormikProps } from 'formik';
import { Form as AntdForm, Spin } from 'antd';

import SubmitButton from '../buttons/SubmitButton';
import { ButtonsContainer } from './styles/Form.styles';
import ClearButton from '../buttons/ClearButton';

interface IProps {
  style?: React.CSSProperties;
  loading: boolean;
  onSubmit: (values: Json) => void;
  onReset?: () => void;
  resetValues?: Json;
  initialValues?: Json;
  enableReinitialize?: boolean;
  validationSchema?: Json;
  allowClear?: boolean;
  clearButtonText?: string;
  submitButtonText?: string;
  validate?: (values?: Json) => void;
}

const Form: React.FC<IProps> = ({
  children,
  style,
  loading,
  onSubmit,
  onReset,
  resetValues,
  initialValues,
  enableReinitialize,
  validationSchema,
  allowClear,
  clearButtonText,
  submitButtonText,
  validate,
}) => {
  const clearButton: (delegate: (args: any) => any) => JSX.Element = (delegate) => {
    const handleClear: () => void = () => {
      delegate(resetValues || {});
      !!onReset && onReset();
    };

    return (
      <ClearButton onClick={handleClear}>
        {clearButtonText}
      </ClearButton>
    );
  };

  const render: (formikBag: FormikProps<any>) => JSX.Element = props => (
    <AntdForm
      style={style}
      onSubmit={props.handleSubmit}
    >
      {children}
      <ButtonsContainer>
        {allowClear && clearButton(props.setValues)}
        <SubmitButton>
          {submitButtonText}
        </SubmitButton>
      </ButtonsContainer>
    </AntdForm>
  );

  return (
    <Spin spinning={loading}>
      <Formik
        initialValues={initialValues || {}}
        enableReinitialize={enableReinitialize}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validate={validate}
        onReset={onReset}
        render={render}
      />
    </Spin >
  );
};

export default Form;
