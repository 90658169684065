import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';

import { TreeSelect, Dropdown, Icon, Spin, Tooltip } from 'antd';
import { TreeNode } from 'antd/lib/tree-select';

import {
  FilterContainer,
  FilterListContainer,
  FilterListContent,
  FilterListItem,
  FilterListLabel,
  FilterListPlaceholder,
  Button,
  ButtonTitle,
  ButtonSubtitle,
  MaxTagPlaceholder,
} from './styles/Filter.styles';
import { normalize } from '../../utils/locale';

interface IProps {
  data: TreeNode[];
  label?: string;
  loading?: boolean;
  filterByTitle?: boolean;
  value: any[];
  onChange: ((value: any, label: any, extra: any) => void) | undefined;
}

const maxTagCountPageSize: number = 5;

const SelectFilter: React.FC<IProps> = ({
  data,
  label,
  loading,
  value,
  onChange,
  filterByTitle,
}) => {
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState<boolean>(true);
  const [listContainer, setListContainer] = useState<HTMLElement | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [maxTagCount, setMaxTagCount] = useState<number>(maxTagCountPageSize);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const debounce = useRef<number>();

  useEffect(
    () => {
      const listContainer: HTMLElement | null = document.getElementById('filter-list');
      setListContainer(listContainer);
    },
    [],
  );

  const className: string = `${label ? label.replace(/\W/g, '') : 'default'}-select-filter`;

  const handleChange = (value: any, label: any, extra: any): void => {
    if (isFocused) return;
    !!onChange && onChange(value, label, extra);
  };

  const handleSearch: (search: string) => void = (search) => {
    setIsTyping(true);
    debounce.current = setTimeout(
      () => { setIsTyping(false); },
      300,
    );
    setSearch(search);
    setIsPlaceholderVisible(!search);
  };

  const handleFilter = (inputValue: string, treeNode: any): boolean => {
    const source: string = filterByTitle
      ? treeNode.props.title
      : treeNode.key;

    return normalize(source).includes(normalize(inputValue));
  };

  const handleClick = (e: React.MouseEvent<any, MouseEvent>): void =>
    setIsDropdownVisible(!isDropdownVisible);

  const handleFocus = (): void => setIsFocused(true);

  const handleBlur = (): void => setIsFocused(false);

  const loadingIcon: JSX.Element = (
    <Fragment>
      <Spin
        size="small"
        style={{ marginRight: '.5rem' }}
      />
      Buscando ...
    </Fragment>
  );

  const increaseMaxTagCount = (): void => setMaxTagCount(maxTagCount + maxTagCountPageSize);
  const resetMaxTagCount = (): void => setMaxTagCount(maxTagCountPageSize);
  const maxTagPlaceholder = (omittedValues: string[]): JSX.Element => (
    <Tooltip title={`Ver mais ${Math.min(omittedValues.length, maxTagCountPageSize)}`}>
      <MaxTagPlaceholder onClick={increaseMaxTagCount}>
        + {omittedValues.length} ...
    </MaxTagPlaceholder>
    </Tooltip>
  );

  const list: React.ReactPortal | null | false =
    !!value && !!value.length && !!listContainer && ReactDOM.createPortal(
      <FilterListItem>
        <FilterListLabel>
          {label}
        </FilterListLabel>
        <TreeSelect
          allowClear={true}
          searchPlaceholder="Resultados"
          treeCheckable={true}
          treeData={data}
          dropdownClassName="hidden"
          value={value}
          onChange={handleChange}
          showSearch={false}
          treeNodeLabelProp="label"
          maxTagCount={maxTagCount}
          maxTagPlaceholder={maxTagPlaceholder}
          onBlur={resetMaxTagCount}
        />
      </FilterListItem>,
      listContainer,
    );

  const filter: JSX.Element = (
    <FilterContainer isPlaceholderVisible={isPlaceholderVisible}>
      <TreeSelect
        className={className}
        autoClearSearchValue={false}
        notFoundContent={isTyping ? loadingIcon : 'Sem resultados'}
        open={isDropdownVisible}
        searchPlaceholder="Busca rápida"
        treeCheckable={true}
        treeData={isTyping ? [] : data}
        treeDefaultExpandedKeys={label ? [label] : undefined}
        filterTreeNode={handleFilter}
        onChange={handleChange}
        onSearch={handleSearch}
        onFocus={handleFocus}
        onBlur={handleBlur}
        searchValue={search}
        value={value}
        dropdownMatchSelectWidth={true}
      />
    </FilterContainer>
  );

  const selected: JSX.Element | false = !!value && !!value.length &&
    <ButtonSubtitle>{value.length} selecionado{value.length > 1 && 's'}</ButtonSubtitle>;

  return (
    <Spin spinning={loading}>
      <Dropdown
        overlay={filter}
        trigger={['click']}
        visible={isDropdownVisible}
      >
        <Button onClick={handleClick}>
          <ButtonTitle hasSubtitle={!!value && !!value.length}>{label}</ButtonTitle>
          {selected}
          <Icon type={`caret-${isDropdownVisible ? 'up' : 'down'}`} />
        </Button>
      </Dropdown>
      {list}
    </Spin>
  );
};

SelectFilter.defaultProps = {
  loading: false,
};

const SelectFilterList: React.FC = () => {
  const { formValues: queriesFormValues } = useSelector((state: Json) => state.queries);

  const hasQueryValuesSelected: boolean = !!queriesFormValues && (
    !!queriesFormValues.columns.filter(col => !!col && !!col.length).length || (
      !!queriesFormValues.filters && (
        (!!queriesFormValues.filters.cities && !!queriesFormValues.filters.cities.length) ||
        (!!queriesFormValues.filters.states && !!queriesFormValues.filters.states.length)
      )
    )
  );

  const placeholder: JSX.Element = (
    <FilterListPlaceholder>
      Os seus filtros aparecerão aqui
    </FilterListPlaceholder>
  );

  return (
    <FilterListContainer>
      Filtrar por:
      <FilterListContent id="filter-list">
        {!hasQueryValuesSelected && placeholder}
      </FilterListContent>
    </FilterListContainer>
  );
};

export { SelectFilterList };
export default SelectFilter;
