import React from 'react';
import { RouteComponentProps } from 'react-router';

import LoginForm from '../components/forms/LoginForm';
import PasswordRecoveryModal from '../components/modals/PasswordRecoveryModal';
// import ConfirmationEmailModal from '../components/modals/ConfirmationEmailModal';
// import { Link } from 'react-router-dom';
import { Page, Container, Row, Subtitle } from './styles/Page.styles';
import { Icon, Background } from './styles/LoginPage.styles';

import background from '../assets/background.jpg';
import logo from '../assets/logo.png';

const LoginPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <Page>
      <Background src={background} />
      <Container center={true}>
        <Icon src={logo} />
        {/* <Title>Brasil Cidades</Title> */}
        <Subtitle>Login</Subtitle>
        <LoginForm />
        <Row>
          {/* <ConfirmationEmailModal /> */}
          <PasswordRecoveryModal />
        </Row>
        {/* <Row> */}
        {/* <Link to="/register">Registrar</Link> */}
        {/* </Row> */}
      </Container>
    </Page>
  );
};

export default LoginPage;
