import { put } from 'redux-saga/effects';
import { message } from 'antd';

import http from '../utils/http';
import createReduxModule from './createReduxModule';
import { normalizeError } from '../utils/error';
import { mapColumnsToTreeNodes } from '../utils/columns';
import { TreeNode } from 'antd/lib/tree-select';

interface IState {
  columns: TreeNode[];
  loading: boolean;
  error: {
    message: string;
    status?: string | number;
    isTimeout?: boolean;
  };
}

const initialState: IState = {
  columns: [],
  loading: false,
  error: { message: '' },
};

const reducer: IReducer<IState> = {
  fetchColumns: (state, action) => ({
    ...state,
    loading: true,
  }),
  fetchColumnsSuccess: (state, action) => ({
    ...state,
    columns: action.payload,
    loading: false,
  }),
  fetchColumnsFailure: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
};

const sagas: ISagas = {
  *fetchColumns({ payload }) {
    try {
      const { data } = yield http.get('/list-columns');
      const nodes: TreeNode[] = mapColumnsToTreeNodes(data);
      yield put(reduxModule.actions.fetchColumnsSuccess(nodes));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao buscar colunas');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.fetchColumnsFailure(errorInfo));
    }
  },
};

const reduxModule = createReduxModule<IState>(
  'columns',
  initialState,
  reducer,
  sagas,
);

export const { types, actions } = reduxModule;
export default reduxModule;
