import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TreeNode } from 'antd/lib/tree-select';
import { formatColumnValue } from './columns';
import { normalize } from './locale';

const getLabel = (key: string, col: ICitiesColumn): string => {
  let label: string = key;
  if (!!col.year) label += ` (${col.year})`;
  return label;
};

export const mapQueryToColumns = (query: ICitiesQuery): ColumnProps<any>[] => {
  if (!query.length) return [];

  const columns: ColumnProps<any>[] = [{
    title: 'Região',
    dataIndex: 'political_area',
    key: 'political_area',
    fixed: true,
    width: '17em',
    sorter: (a, b) => {
      if (!a || !a.key || !b || !b.key) return 0;
      return normalize(a.key) > normalize(b.key) ? 1 : -1
    },
  }];

  query.forEach((result) => {
    !!result && Object.entries(result).forEach(([key, val]) => {
      if (key.match(/political_area|parent_area|area_type|index/)) return;
      if (!columns.some(col => col.key === key)) {
        const label: string = getLabel(key, val);
        columns.push({
          key,
          title: label,
          dataIndex: label,
          sorter: (a, b) => {
            if (!a || !a[key] || !b || !b[key]) return 0;
            const aVal = a[key].replace(/[^0-9.]/g, '');
            const bVal = b[key].replace(/[^0-9.]/g, '');

            return Number(aVal) - Number(bVal);
          },
        });
      }
    });
  });

  return columns;
};

export const mapQueryToData = (query: ICitiesQuery): TreeNode[] => {
  if (!query.length) return [];

  return query.map((item) => {

    const dataEntries = Object.entries(item).map(([key, val]) => {
      if (key.match(/political_area|parent_area|area_type|index/)) return [];
      return [
        getLabel(key, val),
        formatColumnValue(val.value, val.type),
      ];
    }).filter(item => !!item[1]);

    const politicalArea: JSX.Element = !!item.area_type.match(/state|country/)
      ? <b>{item.political_area}</b>
      : <span>{item.political_area}</span>;

    return {
      political_area: politicalArea,
      key: item.political_area,
      ...Object.fromEntries(dataEntries),
    };
  });
};

export const filterNonCityFromQuery = (query: ICitiesQuery): ICitiesQuery => {
  return query.filter(item => item.area_type === 'city');
};
