import React from 'react';
import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const InputGroup = styled(AntdInput.Group)`
  display: flex !important;
`;

export const Input = styled(AntdInput)`
  flex: 1;
`;

const DividerInput = styled(AntdInput)`
  width: 30px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #fff !important;
  pointer-events: none;
`;

export const Divider: React.FC = () => (
  <DividerInput
    placeholder="~"
    disabled={true}
  />
);

export const Prefix = styled.span`
  opacity: 0.5;
`;
