import styled from 'styled-components';

export const FiltersLabel = styled.p`
  font-size: 1rem;
  margin: 0;
`;

export const RangeFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .ant-row.ant-form-item { width: 24%; }
  @media only screen and (max-width: 700px) {
    .ant-row.ant-form-item { width: 32%; }
    .ant-row.ant-form-item:nth-last-child(1),
    .ant-row.ant-form-item:nth-last-child(2) {
      width: 49%;
    }
  }
  @media only screen and (max-width: 480px) {
    .ant-row.ant-form-item { width: 49%; }
  }
  @media only screen and (max-width: 350px) {
    .ant-row.ant-form-item { width: 100%; }
  }
`;
