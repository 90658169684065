import React from 'react';

import withValidation from './withValidation';
import { FieldProps } from 'formik';
import { Input, Icon } from 'antd';

const EmailInput: React.FC<FieldProps> = ({
  field,
}) => {
  return (
    <Input
      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
      autoComplete="username email"
      placeholder="Email"
      allowClear={true}
      {...field}
    />
  );
};

export default withValidation(EmailInput);
