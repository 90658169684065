import styled from 'styled-components';

export const Container = styled.div`
  .ant-collapse-item { border: 0 !important; }
  .ant-collapse-arrow { right: 0 !important; }
  .ant-collapse-content-box { padding: 0 !important; }
  .ant-collapse-header {
    color: #1890ff !important;
    padding: .25rem 0 !important;
    font-weight: bold;
    font-size: .8rem;
  }
`;
