import React, { ComponentType } from 'react';
import { withGoogleMap } from 'react-google-maps';

import { MapElement } from './styles/Map.style';

const withMap = <Props extends {}>(WrappedComponent: ComponentType<Props>): ComponentType<Props> => {
  return (props: Props) => {
    const MapComponent = withGoogleMap(WrappedComponent);

    return (
      <MapComponent
        containerElement={<MapElement />}
        mapElement={<MapElement />}
        {...props}
      />
    );
  };
};

export default withMap;
