import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Item = styled.div`
  color: #1890ff;
  display: inline-block;
  :not(:last-of-type) {
    margin-right: .5rem;
  }
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  :after {
    content: ': '
  }
`;

export const ItemData = styled.span`
`;
