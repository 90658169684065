import React from 'react';

import { Button } from 'antd';

interface IProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: ((event: React.MouseEvent<any, MouseEvent>) => void) | undefined;
}

const ClearButton: React.FC<IProps> = ({
  children,
  disabled,
  loading,
  onClick,
}) => {
  return (
    <Button
      style={{ width: '30%', marginRight: '3%' }}
      type="dashed"
      htmlType="reset"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {children || 'Limpar'}
    </Button>
  );
};

export default ClearButton;
