import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../store/cityQueries';

import ShowOnlyCitiesCheckbox from '../checkbox/ShowOnlyCitiesCheckbox';
import { Container, Item, ItemLabel, ItemData } from './styles/CitiesQueryMeta.styles';

const CitiesQueryMeta: React.FC = () => {
  const { meta: { cities_count, states_count } } = useSelector<any, IState>(store => store.cityQueries);

  const citiesCountMeta: JSX.Element | false = (!!cities_count || Number(cities_count) === 0) && (
    <Item>
      <ItemLabel>Cidades</ItemLabel>
      <ItemData>{Number(cities_count).toLocaleString()}</ItemData>
    </Item>
  );

  const statesCountMeta: JSX.Element | false = (!!cities_count || Number(cities_count) === 0) && (
    <Item>
      <ItemLabel>Estados</ItemLabel>
      <ItemData>{Number(states_count).toLocaleString()}</ItemData>
    </Item>
  );

  const showOnlyCitiesCheckboxMeta: JSX.Element = (
    <Item>
      <ShowOnlyCitiesCheckbox />
    </Item>
  );

  return (
    <Container>
      {citiesCountMeta}
      {statesCountMeta}
      {showOnlyCitiesCheckboxMeta}
    </Container>
  );
};

export default CitiesQueryMeta;
