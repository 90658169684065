import React, { Fragment } from 'react';

import { Tooltip, Icon } from 'antd';

interface Props {
  tooltip: string;
}

const InfoTooltip: React.FC<Props> = ({
  children,
  tooltip,
}) => {
  return (
    <Fragment>
      {children}
      <Tooltip title={tooltip}>
        <Icon
          type="info-circle"
          theme="twoTone"
          style={{ marginLeft: 15 }}
        />
      </Tooltip>
    </Fragment>
  );
};

export default InfoTooltip;
