import { put, select } from 'redux-saga/effects';
import { message } from 'antd';

import createReduxModule from './createReduxModule';
import http from '../utils/http';
import { normalizeError } from '../utils/error';
import { filterNonCityFromQuery } from '../utils/queries';

export interface IState {
  formValues?: any;
  rawQuery: IQuery;
  query: IQuery;
  showOnlyCities: boolean;
  loading: boolean;
  page: number;
  pageSize: number;
  error: {
    message: string;
    status?: string | number;
    isTimeout?: boolean;
  };
}

const initialState: IState = {
  rawQuery: {
    political_areas: [],
    states: [],
    columns: [],
    columns_with_no_data: [],
    political_area_type: [],
  },
  query: {
    political_areas: [],
    states: [],
    columns: [],
    columns_with_no_data: [],
    political_area_type: [],
  },
  showOnlyCities: false,
  page: 1,
  pageSize: 5,
  loading: false,
  error: { message: '' },
};

const reducer: IReducer<IState> = {
  query: (state, action) => ({
    ...state,
    loading: true,
  }),
  querySuccess: (state, action) => ({
    ...state,
    query: action.payload,
    loading: false,
  }),
  queryFailure: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  clearQuery: (state, action) => ({
    ...state,
    query: initialState.query,
  }),
  setPage: (state, action) => ({
    ...state,
    page: action.payload,
  }),
  setFormValues: (state, action) => ({
    ...state,
    formValues: action.payload,
  }),
  setShowOnlyCities: (state, action) => ({
    ...state,
    showOnlyCities: action.payload,
  }),
  setQuery: (state, action) => ({
    ...state,
    query: action.payload,
  }),
  setRawQuery: (state, action) => ({
    ...state,
    rawQuery: action.payload,
  }),
};

const sagas: ISagas = {
  *query({ payload }) {
    try {
      const { data } = yield http.post('/query', payload);
      const { showOnlyCities } = yield select(store => store.queries);
      const query: IQuery = showOnlyCities ? filterNonCityFromQuery(data) : data;
      yield put(reduxModule.actions.setRawQuery(data));
      yield put(reduxModule.actions.querySuccess(query));
    } catch (err) {
      console.error(err);
      const errorInfo = normalizeError(err, 'Falha ao realizar busca');
      message.error(errorInfo.message);
      yield put(reduxModule.actions.queryFailure(errorInfo));
    }
  },
  *setShowOnlyCities({ payload: showOnlyCities }) {
    const { query, rawQuery } = yield select(store => store.queries);
    if (showOnlyCities) {
      const queryWithCitiesOnly: IQuery = filterNonCityFromQuery(query);
      yield put(reduxModule.actions.setQuery(queryWithCitiesOnly));
    } else {
      yield put(reduxModule.actions.setQuery(rawQuery));
    }
  },
};

const reduxModule = createReduxModule<IState>(
  'queries',
  initialState,
  reducer,
  sagas,
);

export const { types, actions } = reduxModule;
export default reduxModule;
