import React from 'react';
import InfoTooltip from '../components/tooltips/InfoTooltip';

import { TreeNode } from 'antd/lib/tree-select';
import { BANNED_COLUMNS, COLUMN_TOOLTIPS } from './constants';

import get from 'lodash/get';
import set from 'lodash/set';

export const mapColumnsToTreeNodes = (columns: string[]): TreeNode[] => {
  const allowedColumns: string[] = columns.filter(
    col => !BANNED_COLUMNS.includes(col),
  );
  const formattedColumns: string[][] = allowedColumns.map(col => col.split('##'));

  const nodes: TreeNode[] = [];

  formattedColumns.forEach((cols) => {
    const column: string = cols[0];
    const indexFound: number = nodes.findIndex(node => node.key === column);
    const i: number = indexFound === -1 ? nodes.length : indexFound;
    let path: string = `[${i}]`;

    const node: TreeNode = get(nodes, path);
    set(nodes, path, {
      title: getColumnTitle(column),
      key: column,
      value: column,
      ...node,
    });

    for (let j = 1; j < cols.length; j++) {
      path += '.children';
      const col = cols[j];
      const children: TreeNode[] = get(nodes, path);
      const indexFound: number = !children ? 0 : children.findIndex((child) => {
        const key = get(child, 'key');
        return key && key.toString().split('##').pop() === col;
      });
      const childIndex: number = !children ? 0 : indexFound === -1 ? children.length : indexFound;
      path += `[${childIndex}]`;

      const node: TreeNode = get(nodes, path);
      const column: string = cols.slice(0, j + 1).join('##');
      set(nodes, path, {
        title: getColumnTitle(column),
        key: column,
        value: column,
        label: column.split('##').slice(1).join(' > '),
        ...node,
      });
    }
  });

  return nodes;
};

export const getColumnTitle = (column: string): string | JSX.Element | undefined => {
  if (!column) return;
  const title: string | undefined = column.split('##').pop();
  const tooltip: string = COLUMN_TOOLTIPS[column];
  if (!tooltip) return title;

  return <InfoTooltip tooltip={tooltip}>{title}</InfoTooltip>;
};

export type ColumnValueType = 'int' | 'float' | 'string' | 'currency' | 'percentage';

const valueFormatter = (type: ColumnValueType): (val: number | string) => string => ({
  int: val => Number(val.toFixed(0)).toLocaleString(),
  float: val => Number(val.toFixed(2)).toLocaleString(),
  string: val => val,
  currency: val => `R$ ${Number(val.toFixed(2)).toLocaleString()}`,
  percentage: val => Number.isNaN(Number(val)) ? '0 %' : `${(val * 100).toFixed(2)} %`,
})[type];

export const formatColumnValue = (value: string | number, type: ColumnValueType): string => {
  try {
    return valueFormatter(type as ColumnValueType)(value) || '-';
  } catch (err) {
    console.warn(`Failed to format value "${value}" to type "${type}". Value "${value}" will be used instead. ${err}`);
    return value ? value.toString() : '-';
  }
};

export const formatColumnValues = (col: IColumn): IColumn => {
  const { values, type } = col;

  const format = (val: string | number): string => {
    try {
      return valueFormatter(type as ColumnValueType)(val);
    } catch (err) {
      console.warn(`Failed to format value "${val}" to type "${type}" on column "${col.column.replace(/##/g, ' > ')}". Value "${val}" will be used instead. ${err}`);
      return val ? val.toString() : '-';
    }
  };

  return { ...col, values: values.map(format) };
};
