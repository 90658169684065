import React, { useState } from 'react';

import { Collapse } from 'antd';
import { Container } from './styles/ShowMoreCollapse.styles';

interface IProps {
  more?: string;
  less?: string;
}

const key: string = 'show-more-collapse';
const ShowMoreCollapse: React.FC<IProps> = ({
  children,
  more,
  less,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = (): void => setIsOpen(!isOpen);

  return (
    <Container>
      <Collapse
        activeKey={isOpen ? key : ''}
        bordered={false}
        expandIconPosition="right"
        onChange={toggle}
      >
        <Collapse.Panel
          key={key}
          header={isOpen ? less : more}
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    </Container>
  );
};

ShowMoreCollapse.defaultProps = {
  more: 'Ver Mais',
  less: 'Ver Menos',
};

export default ShowMoreCollapse;
