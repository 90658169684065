import React from 'react';
import { RouteComponentProps } from 'react-router';

import QueryCitiesForm from '../components/forms/QueryCitiesForm';
import CitiesQueryMeta from '../components/meta/CitiesQueryMeta';
import QueryCitiesTable from '../components/tables/QueryCitiesTable';
import QueryCitiesCharts from '../components/charts/QueryCitiesCharts';
import CitiesQueryMap from '../components/maps/CitiesQueryMap';
import { Tabs, Icon } from 'antd';
import { Page } from './styles/Page.styles';

const QueryCitiesPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <Page>
      <QueryCitiesForm style={{ marginBottom: '2rem' }} />
      <Tabs tabBarExtraContent={<CitiesQueryMeta />}>
        <Tabs.TabPane
          key="table"
          tab={<span><Icon type="table" />Tabela</span>}
        >
          <QueryCitiesTable />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="charts"
          tab={<span><Icon type="line-chart" />Gráficos</span>}
        >
          <QueryCitiesCharts />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="map"
          tab={<span><Icon type="environment" />Mapa</span>}
        >
          <CitiesQueryMap />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

export default QueryCitiesPage;
