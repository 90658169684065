import React from 'react';
import { Tooltip } from 'antd';
import { TreeNode } from 'antd/lib/tree-select';
import { ColumnProps } from 'antd/lib/table';
import { normalize } from './locale';

import get from 'lodash/get';
import set from 'lodash/set';
import { formatColumnValues } from './columns';

export const mapQueryToColumns = (query: IQuery): ColumnProps<object>[] => {
  if (!query.columns.length) return [];
  const formattedColumns: string[][] = query.columns.map(col => col.column.split('##'));

  const columns: ColumnProps<any>[] = [{
    title: 'Região',
    dataIndex: 'area',
    key: 'area',
    fixed: true,
    width: '17em',
    sorter: (a, b) => {
      if (!a || a.key || !b || !b.key) return 0;
      return normalize(a.key) > normalize(b.key) ? 1 : -1
    },
  }];

  formattedColumns.forEach((cols) => {
    const indexFound: number = columns.findIndex(col => col.title === cols[0]);
    const i: number = indexFound === -1 ? columns.length : indexFound;
    let path: string = `[${i}]`;

    const node: TreeNode = get(columns, path);
    set(columns, path, {
      title: cols[0],
      key: cols[0],
      dataIndex: cols[0],
      ...node,
    });

    for (let j = 1; j < cols.length; j++) {
      path += '.children';
      const col = cols[j];
      const children: ColumnProps<object>[] = get(columns, path);
      const indexFound: number = !children ? 0 : children.findIndex(child => child.dataIndex === cols.slice(0, j + 1).join('##'));
      const childIndex: number = !children ? 0 : indexFound === -1 ? children.length : indexFound;
      path += `[${childIndex}]`;

      const node: ColumnProps<object> = get(columns, path);
      const key: string = cols.slice(0, j + 1).join('##');
      const isLeafNode: boolean = key === cols.join('##');

      set(columns, path, {
        key,
        title: <Tooltip title={key.replace(/##/g, ' > ')}>{col}</Tooltip>,
        dataIndex: key,
        sorter: isLeafNode
          ? (a, b) => a[key].replace(/[^0-9.]/g, '') - b[key].replace(/[^0-9.]/g, '')
          : false,
        ...node,
      });
    }
  });

  return columns;
};

export const mapQueryToData = (query: IQuery): TreeNode[] => {
  if (!query.columns.length) return [];

  const entries: [string, (string | number)[]][] = query.columns
    .map(formatColumnValues)
    .map(col => [col.column, col.values]);

  if (!query.columns.length) return [];
  const data: TreeNode[] = query.political_areas.map((area, i) => {
    const formattedArea: JSX.Element = !!query.political_area_type[i].match(/state|country/)
      ? <b>{area}</b>
      : <span>{area}</span>;

    return Object.fromEntries([
      ['key', area],
      ['area', formattedArea],
      ...entries.map(([column, values]) => [column, values[i]]),
    ]);
  });

  return data;
};

export const filterNonCityFromQuery = (query: IQuery): IQuery => {
  const cityIndexes: number[] = [];
  const queryCopy: IQuery = { ...query };

  queryCopy.political_area_type.forEach((areaType, i) => {
    if (areaType === 'city') {
      cityIndexes.push(i);
    }
  });

  const filterCities = (_, i) => cityIndexes.includes(i);

  const politicalAreas: string[] = queryCopy.political_areas.filter(filterCities);
  const states: string[] = queryCopy.states.filter(filterCities);
  const politicalAreaType: ('city' | 'state' | 'country')[] = queryCopy.political_area_type.filter(filterCities);
  const columns: IColumn[] = queryCopy.columns.map(col => ({ ...col, values: col.values.filter(filterCities) }));
  const columnsWithNoData: string[] = queryCopy.columns_with_no_data;

  const queryWithCitiesOnly: IQuery = {
    states,
    columns,
    political_areas: politicalAreas,
    columns_with_no_data: columnsWithNoData,
    political_area_type: politicalAreaType,
  };

  return queryWithCitiesOnly;
};

// Experimental hierarchy tree node map
// export const mapQueryToData = (query: IQuery): TreeNode[] => {
//   if (!query.columns.length) return [];

//   const entries: [string, (string | number)[]][] = query.columns
//     .map(formatColumnValues)
//     .map(col => [col.column, col.values]);

//   const nodes: TreeNode[] = [];

//   query.political_areas.forEach((area, i) => {
//     const type: 'city' | 'state' | 'country' = query.political_area_type[i];
//     const cols: { [key: string]: string | number } =
//       Object.fromEntries(entries.map(([col, vals]) => [col, vals[i]]));

//     if (type === 'country') {
//       const path: string = '[0]';

//       return set(nodes, path, {
//         area,
//         key: area,
//         ...cols,
//         children: [],
//       });
//     }

//     if (type === 'state') {
//       let path: string = '[0].children';
//       const countryIndex: number = get(nodes, path).length;
//       path += `[${countryIndex}]`;

//       return set(nodes, path, {
//         area,
//         key: area,
//         ...cols,
//         children: [],
//       });
//     }

//     if (type === 'city') {
//       let path: string = '[0].children';
//       const state: string = query.states[i];
//       const stateNodes: TreeNode[] = get(nodes, path);
//       const stateIndexFound: number = stateNodes.findIndex(node => node.key === state);
//       const stateIndex: number = stateIndexFound === -1 ? stateNodes.length : 0;

//       const parentNode: TreeNode = stateNodes[stateIndex];
//       const index: number = (parentNode.children as TreeNode[]).length;
//       path += `[${stateIndex}].children[${index}]`;

//       return set(nodes, path, {
//         area,
//         key: area,
//         ...cols,
//       });
//     }
//   });

//   return nodes;
// };
