import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

export const Container = styled.div`
`;

export const Header = styled.div`
  color: #f8d400;
  font-weight: bold;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  font-size: 1.25rem;
  margin-right: .5rem;
`;

export const WarningIcon: React.FC = () => (
  <StyledIcon type="warning" />
);

export const HeaderTitle = styled.p`
  display: inline-block;
  padding-bottom: 0;
  font-size: 1.1rem;
`;

export const Warnings = styled.ul`

`;

export const Warning = styled.li`
`;

export const WarningTitle = styled.p`
  text-transform: capitalize;
`;
