import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';

import { formatColumnValue, ColumnValueType } from './columns';

const getChartData = (query: IQuery, column: IColumn, dataIndex: number, title?: string): IChartData => {
  const politicalAreaType: 'country' | 'state' | 'city' = query.political_area_type[dataIndex];

  let parent: IChartData | undefined = undefined;
  if (politicalAreaType === 'city') {
    const state: string = query.states[dataIndex];
    const stateIndex: number = query.political_areas.indexOf(state);
    parent = getChartData(query, column, stateIndex);
  }

  if (politicalAreaType === 'state') {
    const countryIndex: number = query.political_area_type.indexOf('country');
    parent = getChartData(query, column, countryIndex);
  }

  return {
    parent,
    title: title || query.political_areas[dataIndex],
    label: formatColumnValue(column.values[dataIndex], column.type as ColumnValueType),
    value: column.values[dataIndex] ? column.values[dataIndex].toString().replace(/[^0-9.]/g, '') : 0,
  };
};

export const queryToCityChartData = (query: IQuery, dataIndex: number): object[] => {
  const years: (string | undefined)[] = uniq(query.columns
    .map(col => col.column.split('##').pop())
    .sort());

  return years.map((year) => {
    const yearColumns: IColumn[] = query.columns.filter((col) => {
      return col.column.split('##').pop() === year;
    });

    const entries: [string, IChartData][] = yearColumns
      .map(col => [
        col.column.split('##').slice(0, -1).join('##'),
        getChartData(query, col, dataIndex),
      ]);

    entries.push(['year', { label: year as string, value: year as string }]);

    return Object.fromEntries(entries);
  });
};

export const queryToFilterChartData = (query: IQuery): [string, object[]][] => {
  const data = query.columns.map((col) => {
    const filter: string[] = col.column.split('##');
    const year: string = filter.pop() as string;

    const values: [string, IChartData][] = query.political_areas
      .map((area, i) => [
        area,
        getChartData(query, col, i, 'Valor'),
      ]);

    return Object.fromEntries([
      ['year', { label: year, value: year }],
      ['filter', { label: filter.join(' > '), value: filter }],
      ...values,
    ]);
  });

  return Object.entries(groupBy(data, 'filter.label'));
};
