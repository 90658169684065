import axios, { AxiosResponse, AxiosInstance } from 'axios';

interface IHosts {
  development: string;
  production: string;
  staging?: string;
  demo?: string;
}

const apiHosts: IHosts = {
  development: 'https://api.brcidades.com',
  production: 'https://api.brcidades.com',
  staging: 'https://api.brcidades.com',
  demo: 'https://api.brcidades.com',
};

const { SERVER_ENV } = process.env;

const apiHost: string  = SERVER_ENV ? apiHosts[SERVER_ENV] : apiHosts.development;

const instance: AxiosInstance = axios.create({
  baseURL: apiHost,
});

export const setToken = (token: string, isRehydrate: boolean = false): void => {
  instance.defaults.headers.common.authorization = token;
  !isRehydrate && localStorage.setItem('token:settime', String(Date.now()));
};

export const removeToken = (): void => {
  localStorage.removeItem('persist:root');
  localStorage.removeItem('token:settime');
  delete instance.defaults.headers.common.authorization;
};

export const mapHeadersToData = (...headers: string[]) => (res: AxiosResponse) => {
  headers.forEach(header => res.data[header] = res.headers[header]);
  return res;
};

export default instance;
