import React, { Fragment } from 'react';
import { TooltipProps, TooltipPayload } from 'recharts';

import get from 'lodash/get';
import { transpose } from '../../utils/array';

import { Table, Column, Cell } from './styles/ChartTooltip.styles';

interface IProps extends TooltipProps {
  activeItem: {
    key: string;
    i: number;
  };
  isSingleYear?: boolean;
  title?: string;
}

const ChartTooltip: React.FC<IProps> = ({
  active,
  label,
  payload,
  activeItem,
}) => {
  if (!active) return null;

  const activeItemPayload = payload ? payload.find(item => item.name === activeItem.key) : undefined;
  const data: TooltipPayload[] = activeItemPayload ? [activeItemPayload] : payload || [];

  const renderHeaderColumn = (): JSX.Element => {
    const cells: JSX.Element[] | false = data.map((cell) => {
      const cellPayload = cell.payload[cell.name].payload;

      let cellLabel: string = cell.name.replace(/##/g, ' > ');
      if (!!cellPayload && !!cellPayload.year) {
        cellLabel += ` (${cellPayload.year})`;
      }

      return (
        <Cell
          key={cell.name}
          color={cell.color}
          header={true}
        >
          {cellLabel}
        </Cell>
      );
    });

    return (
      <Column header={true}>
        <Cell header={true}>{label}</Cell>
        {cells}
      </Column>
    );
  };

  const renderDataColumns = (): JSX.Element[] => {
    const cols: IChartData[][] = [];
    data.forEach((item, i) => {
      const cell: IChartData = item.payload[item.name];
      let path = 'parent';

      const row: IChartData[] = [cell];

      while (!!get(cell, path)) {
        row.push(get(cell, path));
        path += '.parent';
      }

      cols.push(row);
    });

    return transpose(cols).map((col, i) => {
      const cells: JSX.Element[] = col.map((cell, j) => {
        const headerCell: JSX.Element = (
          <Cell header={true}>
            {cell && cell.title}
          </Cell>
        );

        return (
          <Fragment key={j}>
            {j === 0 && headerCell}
            <Cell>
              {cell && cell.label}
            </Cell>
          </Fragment>
        );
      });

      return (
        <Column key={i}>
          {cells}
        </Column>
      );
    });
  };

  const table: JSX.Element = (
    <Table>
      {renderHeaderColumn()}
      {renderDataColumns()}
    </Table>
  );

  return table;
};

export default ChartTooltip;
