import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import yup from '../../utils/yup';
import { ObjectSchema } from 'yup';
import { actions } from '../../store/auth';

import Form from './Form';
import PasswordInput from '../inputs/PasswordInput';
import { Field } from 'formik';

interface IProps {
  token: string;
}

interface IValidation {
  password: string;
  passwordConfirmation: string;
}

const validationSchema: ObjectSchema<IValidation> = yup.object().shape({
  password: yup.string().min(6).required(),
  passwordConfirmation: yup.string().min(6)
    .equals(yup.ref('password'), 'Senhas não conferem').required(),
});

const PasswordRecoveryForm: React.FC<IProps> = ({
  token,
}) => {
  const { loading } = useSelector((state: Json) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit: (values: Json) => void = (values) => {
    dispatch(actions.recoverPassword({ ...values, token }));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      loading={loading}
    >
      <Field
        name="password"
        label="Senha"
        component={PasswordInput}
      />
      <Field
        name="passwordConfirmation"
        label="Confirmar Senha"
        component={PasswordInput}
      />
    </Form>
  );
};

export default PasswordRecoveryForm;
