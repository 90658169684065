import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { actions as queriesActions, IState as IQueriesState } from '../../store/queries';
import { actions as cityQueriesActions, IState as ICityQueriesState } from '../../store/cityQueries';

const ShowOnlyCitiesCheckbox: React.FC = () => {
  const { showOnlyCities: queriesShowOnlyCities } = useSelector<any, IQueriesState>(state => state.queries);
  const { showOnlyCities: cityQueriesShowOnlyCities } = useSelector<any, ICityQueriesState>(state => state.cityQueries);
  const dispatch: Dispatch = useDispatch();

  const checked: boolean = queriesShowOnlyCities && cityQueriesShowOnlyCities;

  const handleChange = (e: CheckboxChangeEvent): void => {
    const { checked } = e.target;
    dispatch(queriesActions.setShowOnlyCities(checked));
    dispatch(cityQueriesActions.setShowOnlyCities(checked));
  };

  return (
    <Checkbox
      onChange={handleChange}
      checked={checked}
    >
      Mostrar somente cidades
    </Checkbox>
  );
};

export default ShowOnlyCitiesCheckbox;
