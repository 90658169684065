import React from 'react';

import HeaderNavigation from './HeaderNavigation';
import UserOptions from '../components/options/UserOptions';
import { Container, Content, Group, Title, Logo } from './styles/Header.styles';

import logo from '../assets/textlessLogo.png';

interface IProps {
  children?: React.ReactNode;
}

const Header: React.FC<IProps> = (props) => {
  return (
    <Container>
      <Content>
        <Group>
          <Logo src={logo} />
          <Title>Brasil Cidades</Title>
        </Group>
        <Group style={{ flex: 1 }}>
          <HeaderNavigation />
          <UserOptions />
        </Group>
      </Content>
    </Container>
  );
};

export default Header;
