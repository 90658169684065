import styled from 'styled-components';

export const Page = styled.section`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0.75rem;
  width: 100%;
  max-width: 80rem;
`;

interface IContainerProps {
  center?: boolean;
}

export const Container = styled.div<IContainerProps>`
  background: #fff;
  box-shadow: 0 0 5px 0px #444;
  width: 100%;
  max-width: 30rem;
  ${props => props.center && 'margin: auto;'}
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
`;

interface IColProps {
  block?: boolean;
}

export const Col = styled.div<IColProps>`
  ${p => p.block && `
    flex: 1;
    :not(:last-child) { margin-right: 1rem; }
  `}
`;

export const Title = styled.h1`
  color: #1890ff;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  letter-spacing: .1rem;
  word-spacing: .5rem;
  line-height: 1;
  margin: 0;
`;

export const Subtitle = styled.h2`
  color: rgba(0, 0, 0, 0.55);
  font-weight: 500;
  font-size: 1.25rem;
`;
