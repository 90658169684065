import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import yup from '../../utils/yup';
import { ObjectSchema } from 'yup';
import { actions } from '../../store/auth';

import Form from './Form';
import EmailInput from '../inputs/EmailInput';
import PasswordInput from '../inputs/PasswordInput';
import { Field } from 'formik';
import { Container } from './styles/LoginForm.styles';

interface IValidation {
  email: string;
  password: string;
}

const validationSchema: ObjectSchema<IValidation> = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginForm: React.FC = (props) => {
  const { loading } = useSelector((state: Json) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit: (values: Json) => void = (values) => {
    dispatch(actions.login(values));
  };

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        loading={loading}
      >
        <Field
          name="email"
          label="Email"
          component={EmailInput}
        />
        <Field
          name="password"
          label="Senha"
          component={PasswordInput}
        />
      </Form>
    </Container>
  );
};

export default LoginForm;
