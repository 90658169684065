import { formatColumnValue } from './columns';

const getChartData = (query: ICitiesQuery, item: ICitiesQueryItem, dataKey: string, title?: string): IChartData => {
  let parent: IChartData | undefined = undefined;
  const parentArea: string = item.parent_area;

  if (!!parentArea) {
    const parentItem: ICitiesQueryItem | undefined = query.find(item => item.political_area === parentArea);
    if (!!parentItem) {
      parent = getChartData(query, parentItem, dataKey);
    }
  }

  return {
    parent,
    title: title || item.political_area,
    label: formatColumnValue(item[dataKey] && item[dataKey].value, item[dataKey] && item[dataKey].type),
    value: item[dataKey] && item[dataKey].value ? item[dataKey].value.toString().replace(/[^0-9.]/g, '') : 0,
    payload: item[dataKey],
  };
};

export const citiesQueryItemToCityChartData = (query: ICitiesQuery, item: ICitiesQueryItem): object[] => {
  const entries = Object.keys(item).map((key) => {
    if (key.match(/political_area|parent_area|area_type|index/)) return [];
    return [key, getChartData(query, item, key)];
  });

  return Object.fromEntries(entries.filter(entry => !!entry[1]));
};

export const citiesQueryToFilterChartData = (query: ICitiesQuery) => {
  const filters: string[] = [];
  query.forEach((item) => {
    Object.keys(item).forEach((filter) => {
      if (filter.match(/political_area|parent_area|area_type|index/)) return;
      if (!filters.includes(filter)) {
        filters.push(filter);
      }
    });
  });

  return filters.map((filter) => {
    return query.reduce(
      (acc, cur) => {
        return {
          ...acc,
          [cur.political_area]: getChartData(query, cur, filter, 'Valor'),
        };
      },
      { filter },
    );
  });
};
