import styled from 'styled-components';

export const UserInfo = styled.div`
  cursor: pointer;
`;

export const Name = styled.p`
  display: inline-block;
  margin: 0 .5rem;
`;
