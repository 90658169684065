import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import get from 'lodash/get';
import yup from '../../utils/yup';
import { ObjectSchema } from 'yup';
import { message } from 'antd';
import { actions as columnActions } from '../../store/columns';
import { actions as queryActions } from '../../store/queries';

import Form from './Form';
import CitiesInput from '../inputs/CitiesInput';
import ColumnInput from '../inputs/ColumnInput';
import StatesInput from '../inputs/StatesInput';
import { SelectFilterList } from '../filters/SelectFilter';
import { Field } from 'formik';
import {
  FiltersLabel,
  SelectFiltersContainer,
} from './styles/QueryDataForm.styles';

interface IProps {
  style?: React.CSSProperties;
}

interface IValidation {
  columns: string[][];
  filters: {
    cities: string[];
    states: string[];
  };
}
const validationSchema: ObjectSchema<IValidation> = yup.object().shape({
  columns: yup.array().of(
    yup.array().of(yup.string()),
  ),
  filters: yup.object().shape({
    cities: yup.array().of(yup.string()),
    states: yup.array().of(yup.string()),
  }),
});

const QueryDataForm: React.FC<IProps> = ({
  style,
}) => {
  const { formValues, loading: loadingQuery } = useSelector((state: Json) => state.queries);
  const { loading: loadingColumns, columns } = useSelector((state: Json) => state.columns);
  const loading: boolean = loadingQuery || loadingColumns;
  const dispatch = useDispatch();

  useEffect(() => { dispatch(columnActions.fetchColumns()); }, [dispatch]);

  const handleSubmit: (values: Json) => void = (values) => {
    const cities: string[] | undefined = get(values, 'filters.cities');
    const states: string[] | undefined = get(values, 'filters.states');

    const hasCities: boolean = !!cities && !!cities.length;
    const hasStates: boolean = !!states && !!states.length;

    if (!hasCities && !hasStates) return message.error('Deve-se selecionar ao menos uma cidade ou estado');

    const columns = Array.prototype.concat.apply([], values.columns)
      .filter(Boolean)
      .map(column => ({ dimensions: column.split('##') }));

    const payload = { ...values };

    payload.filters = {
      years: null,
      cities: [],
      population: null,
      income: null,
      ...values.filters,
    };

    dispatch(queryActions.query({ ...payload, columns }));
  };

  const handleReset = (): void => {
    dispatch(queryActions.setFormValues(resetValues));
    dispatch(queryActions.clearQuery());
  };

  const handleValidation = (values: Json | undefined): void => {
    dispatch(queryActions.setFormValues(values));
  };

  const resetValues = { columns: Array(columns.length).fill([]) };

  const columnFields: JSX.Element[] = columns.map((column, i) =>
    <Field
      key={i}
      name={`columns[${i}]`}
      label={column.title}
      component={ColumnInput}
      data={[column]}
    />,
  );

  return (
    <Form
      style={style}
      onSubmit={handleSubmit}
      onReset={handleReset}
      loading={loading}
      validationSchema={validationSchema}
      resetValues={resetValues}
      initialValues={formValues || resetValues}
      enableReinitialize={true}
      allowClear={true}
      submitButtonText="Buscar"
      validate={handleValidation}
    >
      <FiltersLabel>Filtros:</FiltersLabel>
      <SelectFiltersContainer>
        <Field
          name="filters.states"
          label="Selecionar Estados"
          component={StatesInput}
        />
        <Field
          name="filters.cities"
          label="Selecionar Cidades"
          states={get(formValues, 'filters.states')}
          component={CitiesInput}
        />
        {columnFields}
      </SelectFiltersContainer>
      <SelectFilterList />
    </Form>
  );
};

export default QueryDataForm;
